<template>
  <div class="grid-container tab api-key">
    <div class="row-navigation">
      <div class="row-navigation-actions">
        <div :class="{'btn': true, 'btn-add':true, 'disabled': grid_rows.length >= 5}" @click="openAddItemModal">
          <span>+</span> Create New API Key
          <img class="svg" src="../../assets/icons/key.svg"/>
        </div>
        <div class="api-key-limit" v-if="grid_rows.length >= 5">
          <img class="svg" src="../../assets/icons/warning.svg"/>
          API Key Limit reached
        </div>
      </div>
    </div>
    <table class="grid-table">
      <thead>
      <th :key="header" v-for="header in grid_heads">
        <div class="grid-header-data">
          {{ header.label }}
        </div>
      </th>
      </thead>
      <tbody>
      <tr :key="key" v-for="(row, key) in grid_rows">
        <td>{{ row.key }}</td>
        <td>{{ row.created_at }}</td>
        <td>
          <span v-if="row.permission === 'Whitelisted IPs'" class="btn btn-min" @click="() => openWhitelist(row.id)">{{ row.permission }}</span>
          <span v-else>{{ row.permission }}</span>
        </td>
        <td><span :class="getClassForStatus(row.status)">{{ row.status }}</span></td>
        <td>{{ row.sites_count }}</td>
        <td>{{ row.remark }}</td>
        <td>{{ row.credit_limit ? '$'+row.credit_limit : 'Not set' }}</td>
        <td>{{ row.current_balance }}</td>
        <td class="quick-actions">
          <div class="action" @click="() => openItem(row.id)">
            <img class="svg" src="../../assets/icons/edit.svg"/>
          </div>
          <div class="action" @click="() => pauseItem(row.id)">
            <span v-if="row.is_paused"><img class="svg" src="../../assets/icons/play.svg"/></span>
            <span v-else><img class="svg" src="../../assets/icons/pause-2.svg"/></span>
          </div>
          <div class="action" @click="() => removeItem(row.id)">
            <img class="svg" src="../../assets/icons/bin.svg"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <modal :visible="showModalAdd" :close="closeAddItemModal" className="form api-key">
      <div class="header">
        <div v-on:click="closeAddItemModal" class="btn-cross"><img class="svg" src="../../assets/icons/close.svg"/></div>
        <template v-if="addItem.id">
          <div class="title">
           Key
          </div>
          <div class="subtitle">
            Key id #{{addItem.id}}
          </div>
        </template>
        <template v-else>
          <div class="title">
            Add Key
          </div>
        </template>
      </div>
      <div class="body d-form">
        <div class="d-form-group">
          <label class="d-form-label">Select permission Type:</label>
          <div class="d-form-content permission-list">
            <div
                v-for="permission in permissions"
                :key="permission.value"
                :class="{
                  'permission': true,
                  'active': permission.value === addItem.permission,
                }"
                @click="() => {addItem.permission = permission.value; addItem.permission === 'Whitelisted IPs' ? addItem.whitelist = '' : addItem.whitelist = '*'}"
            >
              {{ permission.label }}
            </div>
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Unlimited use:</label>
          <div class="d-form-content content-end">
            <Toggle v-model="addItem.unlimited_usage">
              <template v-slot:label="{ checked, classList }">
                    <span :class="classList.label">
                      <span v-if="checked"><img src="../../assets/icons/check.png"/></span>
                      <span v-else>&nbsp;</span>
                    </span>
              </template>
            </Toggle>
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Credit limit per month:</label>
          <div class="content-end">
            <div class="d-form-content input-symbol-dollar credit-limit">
              <input v-if="addItem.unlimited_usage" class="control disabled" type="text" value="0">
              <input v-else class="control" type="text" v-model="addItem.credit_limit">
            </div>
          </div>
        </div>
        <div class="d-form-group align-flex-start">
          <label class="d-form-label">Remarks</label>
          <div class="d-form-content">
            <input class="control" type="text" v-model="addItem.remark"/>
            <span v-if="addItem.remark?.length > 120" class="error_message">You have exceeded the limit of 120 characters</span>
          </div>          
        </div>
        

        <div class="d-form-group align-flex-start">
          <label class="d-form-label">Whitelist IPs</label>
          <div class="d-form-content">
            <input :class="{'control': true, 'disabled': addItem.permission === 'Login Credentials'}" type="text" v-model="addItem.whitelist"/>
          </div>
        </div>

        <div class="d-form-group">
          <label class="d-form-label"></label>
          <div class="d-form-content">
            <button v-if="addItem.key" class="btn btn-generate-key generated" v-on:click="saveItem">Key Generated</button>
            <button v-else class="btn btn-generate-key" v-on:click="saveItem">Generate Key</button>
          </div>
        </div>
        <div class="footer copy-key-container">
          <div class="title">
            New API Key
          </div>
          <div class="api-key-copy-field">
            <span class="copy-icon" @click="copyToClipboard"><img class="svg" src="../../assets/icons/copy.svg"/></span>
            <span class="key">{{ addItem.key }}</span>
          </div>
        </div>
      </div>
    </modal>
    <modal :visible="showModalRemove" :close="() => {this.showModalRemove = false}" className="dialog">
      <div @click="() => {this.showModalRemove = false}" class="btn-cross"><img class="svg" src="../../assets/icons/close.svg"/></div>
      <div class="header">
        <img class="svg" src="../../assets/icons/error-message.svg"/>
      </div>
      <div class="body">
        <div class="title warning"> Are you sure you want to <b>deactivate</b> this API Key? </div>
        <div class="text">Please note that deactivating an API key is permanent and will terminate the ability of all connected sites to consume Nemesysco’s services.</div>
        <div class="text">To approve this action please type ‘I understand’ below</div>
        <input class="confirmation-input" v-model="confirmationInput"/>
      </div>
      <div class="footer">
        <div @click="() => {this.showModalRemove = false}" class="btn btn-ok">
          Abort
        </div>
        <div @click="acceptRemovingItem" :class="{'btn': true, 'btn-ok': true, 'disabled': confirmationInput !== 'I understand', 'btn-green': confirmationInput === 'I understand'}">
          Apply
        </div>
      </div>
    </modal>
    <modal :visible="showModalPause" :close="() => {this.showModalPause = false}" className="dialog">
      <div @click="() => {this.showModalPause = false}" class="btn-cross"><img class="svg" src="../../assets/icons/close.svg"/></div>
      <div class="header">
        <img class="svg" src="../../assets/icons/error-message.svg"/>
      </div>
      <div class="body">
        <div class="title warning"> Are you sure you want to <b>deactivate</b> this API Key? </div>
        <div class="text">Please note that deactivating an API key is permanent and will terminate the ability of all connected sites to consume Nemesysco’s services.</div>
        <div class="text">To approve this action please type ‘I understand’ below</div>
        <input class="confirmation-input" v-model="confirmationInput"/>
      </div>
      <div class="footer">
        <div @click="() => {this.showModalPause = false}" class="btn btn-ok">
          Abort
        </div>
        <div @click="acceptPauseItem" :class="{'btn': true, 'btn-ok': true, 'disabled': confirmationInput !== 'I understand', 'btn-green': confirmationInput === 'I understand'}">
          Apply
        </div>
      </div>
    </modal>
    <modal :visible="showModalWhitelist" :close="() => {this.showModalWhitelist = false}" className="whitelist api-key">
      <div @click="() => {this.showModalWhitelist = false}" class="btn-cross"><img class="svg" src="../../assets/icons/close.svg"/></div>
      <div class="header">
        <div class="title">Whitelisted IPs</div>
      </div>
      <div class="body">
        <div class="copy-key-container">
          <div class="title">
            API Key
          </div>
          <div class="api-key-copy-field">
            <span class="copy-icon" @click="copyToClipboard"><img class="svg" src="../../assets/icons/copy.svg"/></span>
            <span class="key">{{ addItem.key }}</span>
          </div>
        </div>
        <div class="text permitted-ip"> Permitted IP addresses </div>

        <table class="grid-table permitted-ip">
          <tbody>
          <tr :key="key" v-for="(row, key) in grid_whitelist">
            <td>{{ row.ip }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="footer"></div>
    </modal>
  </div>
</template>

<script>
import Toggle from '@vueform/toggle';

export default {
  components: {Toggle},
  props: {},
  data() {
    return {
      showModalAdd: false,
      showModalRemove: false,
      showModalPause: false,
      showModalWhitelist: false,
      confirmationInput: '',
      addItemInitial: {
        unlimited_usage: false,
        permission: 'Login Credentials',
        whitelist: '*',
      },
      addItem: {},
      grid_heads: [
        {sortable: true, label: 'Api Key Details'},
        {sortable: true, label: 'Creation Date'},
        {sortable: true, label: 'Permissions'},
        {sortable: true, label: 'Status'},
        {sortable: true, label: 'Connected Sites'},
        {sortable: true, label: 'Remark'},
        {sortable: true, label: 'Credit Limit'},
        {sortable: true, label: 'Current Balance'},
        {sortable: true, label: 'Quick Actions'},
      ],
      permissions: [
        {value: 'Login Credentials', label: 'Login Credentials'},        
        {value: 'Whitelisted IPs', label: 'Whitelisted IPs'},
        {value: 'N/A', label: 'N/A'},          
      ],
      grid_whitelist: [
        {
          'ip': '192.168.0.17',
        },{
          'ip': '192.168.0.21',
        },{
          'ip': '192.168.0.32',
        },{
          'ip': '192.168.0.55',
        },
      ],
      grid_rows: [
        {
          'id': 1,
          'key': '23432423324-162378962899',
          'created_at': '1.12.21',
          'permission': 'Login Credentials',
          'status': 'active',
          'sites_count': '1',
          'remark': 'Used in cloud',
          'credit_limit': null,
          'current_balance': '$250',
          'is_paused': true,
        },{
          'id': 2,
          'key': '23432423324-162378962899',
          'created_at': '1.11.21',
          'permission': 'N/A',
          'status': 'inactive',
          'sites_count': '3',
          'remark': 'Given to dev team 1',
          'credit_limit': '50',
          'current_balance': '$30',
          'is_paused': false,
        },{
          'id': 3,
          'key': '23432423324-162378962899',
          'created_at': '1.10.21',
          'permission': 'Whitelisted IPs',
          'status': 'deleted',
          'sites_count': '14',
          'remark': 'Given to dev team 2',
          'credit_limit': null,
          'current_balance': '$200',
          'is_paused': false,
        },{
          'id': 4,
          'key': '23432423324-162378962899',
          'created_at': '1.9.21',
          'permission': 'Whitelisted IPs',
          'status': 'active',
          'sites_count': '7',
          'remark': 'Given to dev team 3',
          'credit_limit': '50',
          'current_balance': '$30',
          'is_paused': false,
        }
      ],
    };
  },
  mounted() {
    this.svgHelper();
  },
  methods: {
    openWhitelist: function (id){
      this.addItem = {...this.grid_rows.find((item) => item.id === id)};
      this.showModalWhitelist = true;
    },
    copyToClipboard(){
      navigator.clipboard.writeText(this.addItem.key);
    },
    getClassForStatus(status) {
      return {
        'grid-status-label': true,
        'grid-status-label__suspended': status === 'deleted',
        'grid-status-label__success': status === 'active',
        'grid-status-label__active': status === 'inactive',
      };
    },
    generateRandomKey(){
      const key_length = 12;
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for ( let i = 0; i < key_length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.addItem.key = result;
    },
    openAddItemModal: function(){
      this.showModalAdd = true;
      this.addItem = {...this.addItemInitial};
    },
    closeAddItemModal: function(){
      this.showModalAdd = false;
      this.addItem = {};
    },
    saveItem: function () {
      this.generateRandomKey();

      if(!this.addItem.id){
        this.addItem.id = this.grid_rows.length > 0 ? (this.grid_rows[this.grid_rows.length - 1].id + 1) : 1;
        this.addItem.editable = true;
        this.grid_rows.push(this.addItem);
      }
      else{
        const oldItem = this.grid_rows.find((item) => item.id === this.addItem.id);
        Object.assign(oldItem, this.addItem);
      }

      setTimeout(() => {this.svgHelper();}, 0);
    },
    removeItem: function (id){
      this.showModalRemove = true;
      this.confirmationInput = '';
      this.addItem = {...this.grid_rows.find((item) => item.id === id)};
    },
    acceptRemovingItem: function (){
      this.showModalRemove = false;
      this.grid_rows = this.grid_rows.filter((item) => item.id !== this.addItem.id);
    },
    pauseItem: function (id){
      this.addItem = this.grid_rows.find((item) => item.id === id);
      if(this.addItem.is_paused) {
        this.showModalPause = true;
        this.confirmationInput = '';
      } else {
        this.acceptPauseItem();
      }
    },
    acceptPauseItem: function (){
      this.showModalPause = false;
      this.addItem.is_paused = !this.addItem.is_paused;
      setTimeout(() => this.svgHelper('.api-key'), 0);
    },
    openItem: function (id){
      this.addItem = {...this.grid_rows.find((item) => item.id === id)};
      this.showModalAdd = true;
    },
  }
}
</script>
<style lang="scss" scoped src="../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../assets/styles/user_profile_details.scss"></style>
