<template>
  <div class="d-form__body user-profile">
    <div class="user-profile-content">
      <div class="with-separator">
        <div class="d-form-group">
          <label class="d-form-label bottom">Profile Picture</label>
          <div class="d-form-content non-required">
            <div class="upload-profile-picture">
              <img id="profile-picture" src="../assets/face.png"/>
              <label for="file-upload" class="upload-button">
                <input id="file-upload" hidden type="file" @change="loadProfilePicture"/>
                <div class="file-upload-button">
                <span class="upload-icon">
                  <img class="svg" src="../assets/icons/upload.svg"/>
                </span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Email Address</label>
          <div class="d-form-content required">
            <input class="control" type="text" value="Johndoe@gmail.com">
          </div>
          <div class="d-form-info">
            <div class="verified-icon-label">
                <span class="verified-icon">
                  <img class="svg" src="../assets/icons/check.png"/>
                </span>
              Verified
            </div>
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Password</label>
          <div class="d-form-content required">
            <input class="control" :type="showPassword ? 'text' : 'password'" value="1234567891235">
            <span :class="{'eye': true, 'show-icon': this.showPassword}" @click="showPassword = !showPassword">
                <img class="svg" src="../assets/icons/eye.svg"/>
              </span>
            <span :class="{'eye': true, 'show-icon': !this.showPassword}" @click="showPassword = !showPassword">
                <img class="svg" src="../assets/icons/eye-close.svg"/>
              </span>
          </div>
          <div class="d-form-info forgot-password">
            <div class="action">Change password</div>
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Full Name</label>
          <div class="d-form-content required">
            <input class="control" type="text" value="First name">
            <input class="control" type="text" value="Surname">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Job Title</label>
          <div class="d-form-content required">
            <Multiselect v-model="user_data.jobTitle" :options="job_titles"/>
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Mobile No.</label>
          <div class="d-form-content required">
            <input class="control" type="text" value="+81 5256525225">
          </div>
        </div>
      </div>
      <div class="with-separator user-profile-content-right">
        <div class="d-form-group">
          <label class="d-form-label">Office No.</label>
          <div class="d-form-content">
            <input class="control" type="text" value="+81 5256525225">
            <div class="ext">
              <label class="d-form-label">EXT.</label>
              <div class="d-form-content">
                <input class="control" type="text" value="123">
              </div>
            </div>
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Country</label>
          <div class="d-form-content">
            <Multiselect v-model="user_data.countryId" :options="userStore.countries"/>
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Personal Web Page</label>
          <div class="d-form-content">
            <input class="control" type="text" value="URL">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Role</label>
          <div class="d-form-content">
            <input class="control disabled" type="text" value="Management">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Status</label>
          <div class="d-form-content">
            <div class="control disabled status active">Active</div>
          </div>
        </div>
        <div class="d-form-group__check">
          <div class="d-form-group__check-text">
            <label class="container-checkbox">
              <input type="checkbox" checked="checked">
              <span class="checkmark"></span>
            </label>
            <div>
              I want to receive Nemesysco’s news & updates.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-form__action">
      <div class="btn activate-button center">
        Update
      </div>
    </div>
  </div>
</template>
<script>

import {useUserStore} from "@/stores/userStore";

export default {
  components: {},
  name: 'UserProfile',
  props: {},
  data() {
    return {
      showModal: false,
      showPassword: false,
      user_data: {
        ...this.userStore.user,
        jobTitle: 'Select',
      },
      job_titles: [
        { value: 'Select', label: 'Select' },
        { value: 'Management', label: 'Management' },
        { value: 'Accounts', label: 'Accounts' },
        { value: 'Sales', label: 'Sales' },
        { value: 'IT', label: 'IT' },
        { value: 'Dev', label: 'Dev' },
        { value: 'Other', label: 'Other' },
      ],
    };
  },
  setup(){
    const userStore = useUserStore();
    userStore.loadCountries();
    return {
      userStore,
    }
  },
  mounted() {
    this.svgHelper();
  },
  methods: {
    loadProfilePicture: function (event){
      const image = document.getElementById('profile-picture');
      image.src = URL.createObjectURL(event.target.files[0]);
    },
    save: function () {
    },
    cancel: function () {

    },
  },
}
</script>
<style lang="scss" scoped src="../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../assets/styles/user_profile.scss"></style>

