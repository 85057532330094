import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Popper from "vue3-popper";
import Multiselect from '@vueform/multiselect';
import { createPinia } from 'pinia';

const app = createApp(App);
app.use(router);
app.use(createPinia());

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
axios.defaults.headers.common['Content-Type'] = 'application/json';
app.use(VueAxios, axios);

app.component('modal', require("./components/controls/modal").default);
app.component("Popper", Popper);
app.component("Multiselect", Multiselect);

app.mixin({
    data() {
        return {};
    },
    methods: {
        svgHelper: function (tag) {
            const selectTag = tag ? (tag+' img.svg') : 'img.svg';
            document.querySelectorAll(selectTag).forEach(function (img) {
                let imgID = img.id;
                let imgClass = img.className;
                let imgURL = img.src;

                fetch(imgURL).then(function (response) {
                    return response.text();
                }).then(function (text) {
                    let parser = new DOMParser();
                    let xmlDoc = parser.parseFromString(text, "text/xml");
                    let svg = xmlDoc.getElementsByTagName('svg')[0];
                    if(!svg) return;
                    if (typeof imgID !== 'undefined') {
                        svg.setAttribute('id', imgID);
                    }
                    if (typeof imgClass !== 'undefined') {
                        svg.setAttribute('class', imgClass + ' replaced-svg');
                    }
                    svg.removeAttribute('xmlns:a');
                    if (!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
                        svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
                    }
                    if(img.parentNode) img.parentNode.replaceChild(svg, img);
                });
            });
        }
    }
});

app.mount('#app');