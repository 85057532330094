<template>
    <div id="settings">
      test
    </div>
</template>

<script>
    export default {
        components: {

        },
        name: 'Settings',
        props: {},
        data() {
            return {
            };
        },
      methods: {
        save: function () {
        },
        cancel: function () {

        },
      }
    }
</script>

<style scoped>
</style>
