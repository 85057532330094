<template>
  <div id="billing-payment" class="billing-form">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>
    <div class="d-wrapper">
      <div class="d-form d-form-payment card-choice">
        <div class="d-form__title">
          <div class="d-form__title-wrap">
            Payment Method
          </div>
        </div>
        <div class="d-form__body">
          <div class="billing-title">Please choose your payment method</div>
          <div class="billing-title-desc">
            <span>*</span> By selecting a payment method you will enjoy the benefits of monthly<br/>
            billing at a 10% discount rate, as well as automatic top-up and higher credit.
          </div>
          <div class="billing-buttons-wrapper">
            <div class="billing-button-wrapper">
              <div :class="paymentType.card ? 'billing-button-active' : ''" class="billing-button" v-on:click="selectPayment(1)">
                  <div class="billing-button-icon"></div>
                  <img src="../../assets/icons/cards.png"/>
              </div>
              <div class="billing-button__text">
                Pay with credit card
              </div>
            </div>
            <div class="billing-button-wrapper">
              <div :class="paymentType.paypal ? 'billing-button-active' : ''" class="billing-button" v-on:click="selectPayment(2)">
                <div class="billing-button-icon"></div>
                <img class="paypal-icon" src="../../assets/icons/paypal.png"/>
              </div>
              <div class="billing-button__text">
                Pay with Paypal
              </div>
            </div>
          </div>
          <div class="d-form-group__check">
            <div class="d-form-group__check-text">
              <label class="container-checkbox">
                <input type="checkbox" checked="checked">
                <span class="checkmark"></span>
              </label>
              <div class="checkbox-description">
                I hereby authorize the monthly amount due, the specified top-up amount (if any), and any transaction
                processing fees to be charged from the credit card/PayPal account specified above.
              </div>
            </div>
          </div>
        </div>
        <div class="d-form__action">
          <div class="btn activate-button center">
            Submit
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="../../../node_modules/@vueform/toggle/themes/default.css"></style>
<script>

import Breadcrumbs from "../controls/breadcrumbs";

export default {
  components: {Breadcrumbs},
  name: 'AdminBillingPayment',
  props: {},
  data() {
    return {
      showModal: false,
      breadcrumbs: [
        {
          "name": "Dashboard",
          "link": "/dashboard",
          "active": false
        },
        {
          "name": "Payment Method",
          "link": "#",
          "active": true
        }
      ],
      paymentType: {
        "card": true,
        "paypal": false
      },
      loadBalanceNotification: false,
      autoBalance: false,
    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    selectPayment: function(type) {
      if(type === 1) {
        this.paymentType.card = true;
        this.paymentType.paypal = false;
      } else {
        this.paymentType.card = false;
        this.paymentType.paypal = true;
      }
    },
    show: function () {
      this.showModal = true;
    },
    hide: function () {
      this.showModal = false;
    },
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../assets/styles/billing.scss"></style>