<template>
  <teleport to="#modals">
    <div v-if="visible" class="modal" @click="onClose">
      <div :class="'modal-content '+(className ? className : '')" @click="(e) => e.stopPropagation()">
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            visible: Boolean,
            className: {
              type: String,
              required: false
            },
            close: {
              type: Function,
              required: false
            },
        },
        data() {
            return {};
        },
        methods: {
          onClose: function (event){
            if(this.close){
              this.close(event);
            }
          }
        },
        watch:{
          visible: function (){
            setTimeout(() => {this.svgHelper('#modals');}, 0);
          }
        }
    }
</script>

<style lang="scss" src="../../assets/styles/modal.scss"></style>
