<template>
    <div class="grid-container tab roles">
      <div class="row-navigation">
        <div class="row-navigation-actions">
          <div class="btn btn-add">
            <span>+</span> Add
          </div>
        </div>
      </div>
      <table class="grid-table">
        <thead>
        <th :key="header" v-for="header in grid_heads">
          <div class="grid-header-data">
            {{ header.label }}
          </div>
        </th>
        </thead>
        <tbody>
        <tr :key="key" v-for="(row, key) in grid_rows">
          <td>{{ row.name }}</td>
          <td class="quick-actions">
            <div class="action">
              <img class="svg" src="../../../assets/icons/folder.svg"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
</template>



<script>

export default {
  components: {},
  name: 'Roles',
  props: {},
  data() {
    return {
      grid_heads: [
        {sortable: true, label: 'Role Name'},
        {sortable: false, label: ''},
      ],
      grid_rows: [
        {
          'name': 'Super admin',
        },
        {
          'name': 'Admin',
        },
        {
          'name': 'Operations',
        },
        {
          'name': 'Billing',
        },
      ],
    };
  },
  mounted() {
    this.svgHelper();
  },
  methods: {}
}
</script>
<style lang="scss" scoped src="../../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../../assets/styles/admin/settings.scss"></style>
