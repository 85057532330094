<template>
    <div v-if="items" class="breadcrumbs">
        <div v-on:click="redirect(item.link)" v-bind:key="key+index" v-for="(item, index) in items"
             :class="item.active ? 'breadcrumbs__item breadcrumbs__item-active' : 'breadcrumbs__item'">
            {{item.name}}
            <span v-if="index < items.length-1">></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumbs',
        props: {
            items: Array,
        },
        data() {
            return {
                key: "breadcrumbs",
            };
        },
        methods: {
            redirect(link) {
                this.$router.push(link);
            }
        }
    }
</script>

<style scoped lang="scss">
    .breadcrumbs {
        margin-top: 30px;
        display: flex;
        align-items: center;
        position: absolute;
        top: 86px;
    }

    .breadcrumbs__item {
        font-size: 14px;
        color: #666666;
        font-weight: 600;
        cursor: pointer;

        &.breadcrumbs__item-active {
            color: #77b5ff;
        }
    }

    .breadcrumbs__item span {
        margin-right: 10px;
        margin-right: 10px;
    }

</style>
