import { defineStore } from 'pinia'
import axios from "axios";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore('userStore', {
    state: () => {
        return {
            user: null,
            organization: null,
            countries: null,
        }
    },
    getters: {
        getAccountTypeForImg: (state) => {
            switch (state.organization?.accountTypeId.toString()){
                case '1': return 'dev';
                case '2': return 'pre-paid';
                case '3': return 'silver';
                case '4': return 'gold';
                case '5': return 'platinum';
                case '6': return 'platinum';
                default: return 'dev';
            }
        },
        // type is automatically inferred because we are not using `this`
        fullName: (state) => {
            if(state.user) return state.user.firstName + " " + state.user.lastName;
            else return '';
        },
        // here we need to add the type ourselves (using JSDoc in JS). We can also
        // use this to document the getter
    },
    actions: {
        loadUser() {
            return axios.post('users/self')
                .then((result) => {
                    this.user = result.data.user;
                    this.organization = result.data.organization;
                });
        },
        loadCountries() {
            if(!this.countries) return axios.get('CountrySet')
                .then((response) => {
                    return this.countries = response.data;
                });
            else return new Promise((resolve) => {
                resolve(this.countries);
            });
        },
        login(data) {
            return axios.post('users/authenticate', data)
                .then((result) => {
                    localStorage.setItem('token', result.data.token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token;
                });
        },
        logout() {
            localStorage.removeItem('token');
            return axios.post('users/logout')
                .then((res) => {                    
                    console.log(res);
                })
        }
    },
})