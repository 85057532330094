<template>
    <div id="site-form">
        <breadcrumbs :items="breadcrumbs"></breadcrumbs>
        <div class="site-form-wrapper">
            <div class="d-wrapper">
                <div class="d-form">
                    <div class="d-form__title">
                        <div class="d-form__title-wrap">
                            <div class="d-form__title-wrap-form">
                                Site Details
                            </div>
                            <div class="d-form__title-buttons">
                                <div class="d-form-btn-info">ACTIVE</div>
                                <div class="d-form-btn-suspend" @click="() => {this.suspendDialogModal = true}">
                                    <span>
                                        <img class="svg" src="../assets/icons/s-pause.svg">
                                    </span>
                                    <span>
                                        Suspend
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="site-form-licence">
                        <div class="site-form-licence__label">License status</div>
                        <div class="site-form-licence__info">Communication lost</div>
                        <div class="site-form-licence__renew">Renew license</div>
                    </div>
                    <div class="form-separator"></div>
                    <div class="d-form__body">
                        <div class="d-form-group">
                            <label class="d-form-label">Site Name</label>
                            <div class="d-form-content">
                              <input class="control" type="text" v-model="site.name">
                            </div>
                        </div>
                        <div class="d-form-group">
                            <label class="d-form-label">Site Password</label>
                            <div class="d-form-content">
                              <input class="control" type="password" v-model="site.password">
                            </div>
                        </div>
                        <div class="d-form-group">
                            <label class="d-form-label">Site ID</label>
                            <div class="d-form-content">
                              <input class="control disabled-2" type="text" v-model="site.siteId">
                            </div>
                        </div>
                        <div class="d-form-group">
                            <label class="d-form-label">Remote Site IP</label>
                            <div class="d-form-content">
                              <input class="control" type="text" v-model="site.siteIP">
                            </div>
                        </div>
                        <div class="d-form-group">
                            <label class="d-form-label">Application type</label>
                            <div class="d-form-content">
                              <Multiselect v-model="site.appType" :options="appType"/>
                            </div>
                        </div>
                        <div class="d-form-group">
                            <label class="d-form-label">No. of seats</label>
                            <div class="d-form-content">
                              <input class="control" type="text" v-model="site.seats">
                            </div>
                        </div>
                        <div class="d-form-group">
                            <label class="d-form-label">Country</label>
                            <div class="d-form-content">
                              <Multiselect v-model="site.country" :options="countries"/>
                            </div>
                        </div>
                        <div class="d-form-group">
                            <label class="d-form-label">Type of use</label>
                            <div class="d-form-content">
                              <Multiselect v-model="site.type" :options="types"/>
                            </div>
                        </div>
                        <div class="d-form-group__check">
                            <div class="d-form-group__check-text">
                                <label class="container-checkbox">
                                    <input type="checkbox" checked="checked">
                                    <span class="checkmark"></span>
                                </label>
                                <div>
                                    Share anonymized data with Nemesysco
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-form__action">
                        <div @click="updateForm" class="btn activate-button center">
                            Update
                        </div>
                    </div>
                    <div class="delete-site-link">
                        Delete Site
                    </div>
                </div>
            </div>
            <div class="site-form-data">
                <div class="line-site-data">
                    <div class="line-site-data__item">
                        <div class="line-site-data__item-r">
                            <div class="line-site-data__item-r-t">6,532</div>
                            <div class="line-site-data__item-r-txt">Files</div>
                            <div class="triangle-down triangle-down-red"></div>
                        </div>
                        <div class="line-site-data__item-desc">
                            Total requests
                        </div>
                    </div>
                    <div class="line-site-data__item">
                        <div class="line-site-data__item-r">
                            <div class="line-site-data__item-r-t">12,698</div>
                            <div class="line-site-data__item-r-txt">min</div>
                            <div class="triangle-up triangle-up-green"></div>
                        </div>
                        <div class="line-site-data__item-desc">
                            Total minutes
                        </div>
                    </div>
                    <div class="line-site-data__item">
                        <div class="line-site-data__item-r">
                            <div class="line-site-data__item-r-t">798</div>
                            <div class="line-site-data__item-r-txt">Files</div>
                            <div class="triangle-up triangle-up-green"></div>
                        </div>
                        <div class="line-site-data__item-desc line-site-data__item-error">
                            Total requests
                        </div>
                    </div>
                    <div class="line-site-data__item">
                        <div class="line-site-data__item-r">
                            <div class="line-site-data__item-r-t">$21,983</div>
                        </div>
                        <div class="line-site-data__item-desc">
                            Charge per date range
                        </div>
                    </div>
                </div>
                <div class="chart-container breakdown-chart">
                  <div class="chart-header-container">
                    <div class="chart-title">
                      Data Breakdown
                    </div>
                    <div class="chart-btn-group">
                      <div
                          v-for="(line, index) in chartLines"
                          :key="line.name"
                          :class="'btn '+line.name.toLowerCase()"
                          @click="activeChartLine = index"
                      >
                        {{line.name}}
                      </div>
                      <div class="chart-datepicker">
                        <Datepicker v-model="date" :enableTimePicker="false" range twoCalendars></Datepicker>
                      </div>
                    </div>
                  </div>
                  <v-chart class="chart-graph" :option="options"/>
                </div>
                <div class="grid-container site-form-table">
                    <div class="row-navigation">
                        <div class="title">Recent activity</div>
                        <div class="row-navigation-exports">
                            <div class="export-button">
                                <img class="svg" src="../assets/icons/pdf.svg"/>
                            </div>
                            <div class="export-button">
                                <img class="svg" src="../assets/icons/csv.svg"/>
                            </div>
                            <div class="export-button">
                                <img class="svg" src="../assets/icons/json-file.svg"/>
                            </div>
                        </div>
                    </div>
                    <table class="grid-table">
                        <thead>
                        <th class="checkbox-th">
                            <label class="container-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </th>
                        <th :key="header" v-for="header in grid_heads">
                            <div class="grid-header-data">
                                {{ header.label }}
                                <div v-if="header.sortable" class="grid-sort-order">
                                    <div class="triangle-up"></div>
                                    <div class="triangle-down"></div>
                                </div>
                            </div>
                        </th>
                        </thead>
                        <tbody>
                        <tr :key="key" v-for="(row, key) in grid_rows">
                            <td class="checkbox-tr">
                                <label class="container-checkbox">
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </td>
                            <td>{{ row.date }}</td>
                            <td>{{ row.channels }}</td>
                            <td>{{ row.application }}</td>
                            <td>{{ row.requests }}</td>
                            <td>{{ row.failed_requests }}</td>
                            <td>{{ row.total_min }}</td>
                            <td>{{ row.charge }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <modal :visible="updateErrorModal" :close="() => {this.updateErrorModal = false}" className="info">
            <slot>
                <div class="data-modal">
                    <div class="modal-icon">
                        <img class="svg" src="../assets/icons/approved.svg"/>
                    </div>
                    <div class="modal-title">
                        OOPS!<br/>
                        Something went wrong…Please try again
                    </div>
                    <div class="modal-description">
                        Reason for error : lorem ipsum…
                    </div>
                    <div @click="() => {this.updateErrorModal = false}" class="btn btn-close">
                      Close
                    </div>
                </div>
            </slot>
        </modal>
        <modal :visible="suspendDialogModal" :close="() => {this.suspendDialogModal = false}" className="dialog">
          <div @click="() => {this.suspendDialogModal = false}" class="btn-cross"><img class="svg" src="../assets/icons/close.svg"/></div>
          <div class="header">
            <img class="svg" src="../assets/icons/error-message.svg"/>
          </div>
          <div class="body">
            Putting this site in suspend mode will discontinue the site’s daily license activation beginning at midnight tonight.
            After which, the site’s audio analysis functionality will be disabled.
            This action can be reversed at any time by reactivating the site.
            Are you sure you want to suspend this site?
          </div>
          <div class="footer">
            <div @click="acceptSuspend" class="btn btn-ok">
              Yes
            </div>
            <div @click="() => {this.suspendDialogModal = false}" class="btn btn-ok">
              No
            </div>
          </div>
        </modal>
      <modal :visible="permissionUpgradeModal" :close="() => {this.permissionUpgradeModal = false}" className="info">
        <div @click="() => {this.permissionUpgradeModal = false}" class="btn-cross"><img class="svg" src="../assets/icons/close.svg"/></div>
        <div class="data-modal">
          <div class="modal-icon">
            <img class="svg" src="../assets/icons/approved.svg"/>
          </div>
          <div class="modal-title">
            Oops...<br/>
            Permission Upgrade require!
          </div>
          <div class="modal-description">
            You have attempted to select the <span class="text-attention">{{activeAppTypeLabel}}</span> service which requires a higher permission level.
            To upgrade your permission level, please click ‘Request an upgrade’ below.
          </div>
          <div @click="() => {this.permissionUpgradeModal = false}" class="btn btn-close">
            Request an upgrade
          </div>
        </div>
      </modal>
    </div>
</template>

<script>

    import Datepicker from 'vue3-date-time-picker';
    import Breadcrumbs from "./controls/breadcrumbs"
    import {BarChart, PieChart} from "echarts/charts";
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent, GridComponent,
    } from "echarts/components";
    import VChart from "vue-echarts";
    import {use} from "echarts/core";
    import {CanvasRenderer} from "echarts/renderers";

    use([
        CanvasRenderer,
        BarChart,
        PieChart,
        TitleComponent,
        TooltipComponent,
        LegendComponent,
        GridComponent,
    ]);

    const dates = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(() => '10/21')

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
    }


    export default {
        components: {Breadcrumbs, VChart, Datepicker},
        name: 'SiteForm',
        props: {},
        data() {
            return {
                suspendDialogModal: false,
                updateErrorModal: false,
                permissionUpgradeModal: false,
                breadcrumbs: [
                    {
                        "name": "Dashboard",
                        "link": "/dashboard",
                        "active": false
                    },
                    {
                        "name": "Site Details",
                        "link": "#",
                        "active": true
                    }
                ],
                date: null,
                site: {
                    name: 'Sony CS1',
                    password: '123456789',
                    siteId: '0523',
                    siteIP: '168.38.55.1',
                    appType: '1',
                    seats: '56',
                    country: '2',
                    type: '3'
                },
                appType: [
                    { value: '1', label: 'QA7' },
                    { value: '2', label: 'ITN' },
                    { value: '3', label: 'ITR' },
                    { value: '4', label: 'ITZ' },
                    { value: '5', label: 'ITF' },
                    { value: '6', label: 'IOZ' },
                ],
                countries: [
                    { value: '1', label: 'Israel' },
                    { value: '2', label: 'Spain' },
                    { value: '3', label: 'USA' },
                ],
                types: [
                  { value: '1', label: 'Technology' },
                  { value: '2', label: 'Telecommunications' },
                  { value: '3', label: 'Health Care' },
                  { value: '4', label: 'Financials' },
                  { value: '5', label: 'Real Estate' },
                  { value: '6', label: 'Consumer Discretionary' },
                  { value: '7', label: 'Consumer Staples' },
                  { value: '8', label: 'Industrials' },
                  { value: '9', label: 'Basic Materials' },
                  { value: '10', label: 'Energy' },
                  { value: '11', label: 'Utilities' },
                ],
                activeChartLine: 0,
                chartLines: [
                  {
                    name: 'Time',
                    color: '#5394b2',
                    data: dates.map(() => getRandomInt(5, 60)),
                  },{
                    name: 'Charge',
                    color: '#77b5ff',
                    data: dates.map(() => getRandomInt(5, 60)),
                  },{
                    name: 'Files',
                    color: '#4a637a',
                    data: dates.map(() => getRandomInt(5, 60)),
                  },
                ],
                grid_heads: [
                    {sortable: false, label: 'Date'},
                    {sortable: false, label: 'Channels'},
                    {sortable: false, label: 'Application type'},
                    {sortable: false, label: 'Total requests'},
                    {sortable: true, label: 'Failed Requests'},
                    {sortable: true, label: 'Total Min'},
                    {sortable: true, label: 'Daily Charge'}
                ],
                grid_rows: [
                    {
                        'date': '7.122',
                        'channels': '56',
                        'application': 'QA7',
                        'requests': '300',
                        'failed_requests': '0',
                        'total_min': '203',
                        'charge': '$454',
                    },
                    {
                        'date': '7.122',
                        'channels': '56',
                        'application': 'QA7',
                        'requests': '300',
                        'failed_requests': '0',
                        'total_min': '203',
                        'charge': '$454',
                    },
                    {
                        'date': '7.122',
                        'channels': '56',
                        'application': 'QA7',
                        'requests': '300',
                        'failed_requests': '0',
                        'total_min': '203',
                        'charge': '$454',
                    },
                    {
                        'date': '7.122',
                        'channels': '56',
                        'application': 'QA7',
                        'requests': '300',
                        'failed_requests': '0',
                        'total_min': '203',
                        'charge': '$454',
                    },
                    {
                        'date': '7.122',
                        'channels': '56',
                        'application': 'QA7',
                        'requests': '300',
                        'failed_requests': '0',
                        'total_min': '203',
                        'charge': '$454',
                    },
                    {
                        'date': '7.122',
                        'channels': '56',
                        'application': 'QA7',
                        'requests': '300',
                        'failed_requests': '0',
                        'total_min': '203',
                        'charge': '$454',
                    }
                ],
            };
        },
        computed: {
          activeAppTypeLabel(){
            return this.appType.find((element) => element.value === this.site.appType).label;
          },
          options: function () {
            return {
              xAxis: {
                data: dates
              },
              yAxis: {
                type: 'value'
              },
              color: this.chartLines[this.activeChartLine].color,
              series: [{
                type: 'bar',
                data: this.chartLines[this.activeChartLine].data,
                barWidth: 35,
                itemStyle: {
                  backgroundColor: '#fff'
                },
              }],
            }
          },
        },
        mounted() {
          this.svgHelper('#site-form')
        },
        methods: {
            updateForm(){
              if(['5', '6'].includes(this.site.appType)){
                this.permissionUpgradeModal = true;
                return;
              }
              this.updateErrorModal = true;
            },
            acceptSuspend: function () {
              this.suspendDialogModal = false;
            },
            cancel: function () {

            },
        }
    }
</script>
<style lang="scss" scoped src="../assets/styles/site.scss"></style>
<style lang="scss" scoped src="../assets/styles/components.scss"></style>

