<template>
  <div class="grid-container tab invoices">
    <div class="row-navigation">
      <div class="title">Recent Transactions</div>
      <div class="row-navigation-exports">
        <div class="export-button">
          <img class="svg" src="../../../assets/icons/pdf.svg"/>
        </div>
        <div class="export-button">
          <img class="svg" src="../../../assets/icons/csv.svg"/>
        </div>
        <div class="export-button">
          <img class="svg" src="../../../assets/icons/json-file.svg"/>
        </div>
      </div>
    </div>
    <table class="grid-table">
      <thead>
      <th :key="header" v-for="header in grid_heads">
        <div class="grid-header-data">
          {{ header.label }}
          <div v-if="header.sortable" class="grid-sort-order">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
        </div>
      </th>
      </thead>
      <tbody>
      <tr :key="key" v-for="(row, key) in grid_rows">
        <td>{{ row.invoiceId }}</td>
        <td>{{ row.invoiceDate }}</td>
        <td>{{ row.totalSites }}</td>
        <td>{{ row.totalDates }}</td>
        <td>{{ row.totalMinutes }}</td>
        <td>{{ row.invoiceTotal }}</td>
        <td>
            <span v-if="row.typeStatus === '1'" class="grid-status-label grid-status-label__success">
              {{ row.status }}
            </span>
            <span v-if="row.typeStatus === '2'" class="grid-status-label grid-status-label__active">
              {{ row.status }}
            </span>
            <span  v-if="row.typeStatus === '3'" class="grid-status-label grid-status-label__suspended">
                {{ row.status }}
            </span>
        </td>
        <td class="quick-actions">
          <div class="action">
            <img class="svg" src="../../../assets/icons/folder.svg"/>
          </div>
          <div class="action" :class="{'hidden': row.typeStatus !== '2'}">
            <img class="svg" src="../../../assets/icons/paper-plane.svg"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

export default {
  components: {},
  props: {},
  data() {
    return {
      sendNotification: false,
      showModal: false,
      date: null,
      payments: {
        tooltip: {
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: ['6/21', '7/21', '8/21', '9/21', '10/21', '11/21', '12/21'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [100, 1000, 2000, 2500, 1800, 2500, 1100],
            type: 'bar',
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },

          }
        ],
        color: '#77b5ff'
      },
      billing: {},
      grid_heads: [
        {sortable: false, label: 'Invoice ID'},
        {sortable: false, label: 'Invoice Date'},
        {sortable: false, label: 'Total active sites'},
        {sortable: false, label: 'Total days charged'},
        {sortable: true, label: 'Total minutes charged'},
        {sortable: true, label: 'Invoice total'},
        {sortable: true, label: 'Status'},
        {sortable: false, label: 'Action'}
      ],
      grid_rows: [
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'Paid',
          'typeStatus': '1'
        },
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'Due',
          'typeStatus': '2'
        },
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'Due',
          'typeStatus': '2'
        },
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'OVERDUE',
          'typeStatus': '3'
        },
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'Paid',
          'typeStatus': '1'
        },
      ],
    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../../assets/styles/admin/company_details.scss"></style>