<template>
  <div class="user_profile_tab">
    <UserProfile />
  </div>
</template>
<script>

import UserProfile from "../user_profile";

export default {
  components: {UserProfile},
  name: 'UserProfileTab',
}
</script>
<style lang="scss" scoped src="../../assets/styles/user_profile_details.scss"></style>

