<template>
  <div class="sidebar">
    <div class="sidebar__wrapper">
      <div class="sidebar_item sidebar_item_logo">
        <router-link to="/">
          <img src="../assets/nemesysco-logo.png"/>
        </router-link>
        <img class="account-lvl" :src="require('../assets/icons/account-'+userStore.getAccountTypeForImg+'-lvl.png')"/>
        <div class="user-info">
          <div>
            <router-link to="/user/profile">
              <div class="top-header-user-data__user-icon">
                <img src="../assets/face.png"/>
              </div>
            </router-link>
          </div>
          <div>
            <router-link to="/user/profile" class="user-name-link">
              <div class="name">{{userStore.fullName}}</div>
              <div class="job">{{userStore.user?.role.description}}</div>
            </router-link>
            <img class="company-logo" src="../assets/icons/microsoft-button.png"/>
          </div>
        </div>
      </div>
      <div class="sidebar_item parent-item-1">
        <router-link to="#" v-on:click="openChild(1)" :class="currentRouteName === 'BillingOverview' || currentRouteName === 'BillingPayment' ? 'sidebar_item_active' : ''">
          <span class="sidebar_item-icon">
            <img class="svg default-color" src="../assets/icons/microsoft.svg"/>
          </span>
          <span class="sidebar_item-text">
                Admin
          </span>
          <span class="arrow-sidebar arrow-sidebar-1">
            <img class="svg" src="../assets/icons/next.svg"/>
          </span>
        </router-link>
        <div class="child-side-bar-items child-side-bar-items-1">
          <router-link to="/admin/users" :class="currentRouteName === 'AdminUsers' ? 'sidebar_item_active' : ''">Users</router-link>
          <router-link to="/admin/companies" :class="currentRouteName === 'AdminCompanies' ? 'sidebar_item_active' : ''">Companies</router-link>
          <router-link to="#" :class="currentRouteName === '' ? 'sidebar_item_active' : ''">Reports</router-link>
          <router-link to="/admin/settings" :class="currentRouteName === 'AdminSettings' ? 'sidebar_item_active' : ''">Settings</router-link>
          <router-link to="/admin/notifications" :class="currentRouteName === 'AdminNotifications' ? 'sidebar_item_active' : ''">Notifications</router-link>
        </div>
      </div>
      <div class="sidebar_item">
        <router-link :class="currentRouteName === 'Dashboard' ? 'sidebar_item_active' : ''" to="/dashboard">
            <span class="sidebar_item-icon">
              <img class="svg" src="../assets/icons/dashboard.svg"/>
            </span>
          <span class="sidebar_item-text">
                Dashboard
            </span>
        </router-link>
      </div>
      <div class="sidebar_item">
        <router-link to="#">
            <span class="sidebar_item-icon">
              <img class="svg" src="../assets/icons/reports.svg"/>
            </span>
          <span class="sidebar_item-text">
                Reports
            </span>
        </router-link>
      </div>
      <div class="sidebar_item parent-item-2">
        <router-link to="#" v-on:click="openChild(2)" :class="currentRouteName === 'BillingOverview' || currentRouteName === 'BillingPayment' ? 'sidebar_item_active' : ''">
          <span class="sidebar_item-icon">
            <img class="svg" src="../assets/icons/billing.svg"/>
          </span>
          <span class="sidebar_item-text">
                Billing
          </span>
          <span class="arrow-sidebar arrow-sidebar-2">
            <img class="svg" src="../assets/icons/next.svg"/>
          </span>
        </router-link>
        <div class="child-side-bar-items child-side-bar-items-2">
            <router-link to="/billing/overview" :class="currentRouteName === 'BillingOverview' ? 'sidebar_item_active' : ''">Overview</router-link>
            <router-link to="/billing/payment" :class="currentRouteName === 'BillingPayment' ? 'sidebar_item_active' : ''">Payment Method</router-link>
        </div>
      </div>
      <div class="sidebar_item parent-item-3">
        <router-link to="#" v-on:click="openChild(3)" :class="currentRouteName === 'UserProfile' || currentRouteName === 'OrganizationDetails' ? 'sidebar_item_active' : ''">
          <span class="sidebar_item-icon">
            <img class="svg" src="../assets/icons/settings.svg"/>
          </span>
          <span class="sidebar_item-text">
                Settings
          </span>
          <span class="arrow-sidebar arrow-sidebar-3">
            <img class="svg" src="../assets/icons/next.svg"/>
          </span>
        </router-link>
        <div class="child-side-bar-items child-side-bar-items-3">
          <router-link to="/organization/details" :class="currentRouteName === 'OrganizationDetails' ? 'sidebar_item_active' : ''">Organization details</router-link>
          <router-link to="/settings/users" :class="currentRouteName === 'SettingsUsers' ? 'sidebar_item_active' : ''">Users</router-link>
          <router-link to="/settings/api-keys" :class="currentRouteName === 'SettingsApiKeys' ? 'sidebar_item_active' : ''">API Keys</router-link>
        </div>
      </div>
      <div class="sidebar-separator"></div>
      <div class="sidebar_item">
        <router-link to="#">
          <span class="sidebar_item-icon">
            <img class="svg" src="../assets/icons/tutorials.svg"/>
          </span>
          <span class="sidebar_item-text">
                Tutorials
            </span>
        </router-link>
      </div>
      <div class="sidebar_item">
        <router-link to="#">
          <span class="sidebar_item-icon">
            <img class="svg" src="../assets/icons/lva.svg"/>
          </span>
          <span class="sidebar_item-text">
                LVA Resources
            </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/stores/userStore";

export default {
  name: 'Sidebar',
  props: {
    msg: String
  },
  setup(){
    const userStore = useUserStore();
    return {
      userStore,
    }
  },
  watch: {
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted() {

  },
  methods: {
    openChild: function (element) {
      let menuItem = document.querySelector('.child-side-bar-items-'+String(element));
      let arrowSidebar = document.querySelector('.arrow-sidebar-'+String(element));
      if(menuItem.style.display === 'block') {
        menuItem.style.display = 'none';
        arrowSidebar.classList.remove('rotated-arrow');
      } else{
        menuItem.style.display = 'block';
        arrowSidebar.classList.add('rotated-arrow');
      }
    }
  }
}
</script>
<style lang="scss" src="../assets/styles/sidebar.scss" scoped></style>