<template>
  <div class="grid-container tab users">
    <div class="row-navigation">
      <div class="row-navigation-actions">
        <div class="btn btn-add">
          <span>+</span> Invite new user
        </div>
      </div>
      <div class="row-navigation-exports">
        <div class="row-navigation-exports">
          <div class="export-button">
            <img class="svg" src="../../assets/icons/pdf.svg"/>
          </div>
          <div class="export-button">
            <img class="svg" src="../../assets/icons/csv.svg"/>
          </div>
          <div class="export-button">
            <img class="svg" src="../../assets/icons/json-file.svg"/>
          </div>
        </div>
      </div>
    </div>
    <table class="grid-table">
      <thead>
      <th class="checkbox-th">
        <label class="container-checkbox">
          <input type="checkbox" checked="checked">
          <span class="checkmark"></span>
        </label>
      </th>
      <th :key="header" v-for="header in grid_heads">
        <div class="grid-header-data">
          {{ header.label }}
          <div v-if="header.sortable" class="grid-sort-order">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
        </div>
      </th>
      </thead>
      <tbody>
      <tr :key="key" v-for="(row, key) in grid_rows">
        <td class="checkbox-tr">
          <label class="container-checkbox">
            <input type="checkbox">
            <span class="checkmark"></span>
          </label>
        </td>
        <td>{{ row.id }}</td>
        <td>{{ row.username }}</td>
        <td>{{ row.first_name }}</td>
        <td>{{ row.surname }}</td>
        <td>{{ row.role }}</td>
        <td class="quick-actions">
          <div class="action">
            <img class="svg" src="../../assets/icons/remove.svg"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>



<script>

export default {
  components: {},
  name: 'Users',
  props: {},
  data() {
    return {
      grid_heads: [
        {sortable: true, label: 'User ID'},
        {sortable: true, label: 'Username'},
        {sortable: true, label: 'First name'},
        {sortable: true, label: 'Surname'},
        {sortable: true, label: 'Role'},
        {sortable: false, label: 'Actions'},
      ],
      grid_rows: [
        {
          'id': '100201',
          'username': 'TeleOps_1',
          'first_name': 'John',
          'surname': 'Doe',
          'role': 'Owner'
        },{
          'id': '100202',
          'username': 'Adminime',
          'first_name': 'James',
          'surname': 'Brown',
          'role': 'Owner'
        },{
          'id': '100203',
          'username': 'Smithtech',
          'first_name': 'Jill',
          'surname': 'Black',
          'role': 'Owner'
        },{
          'id': '100305',
          'username': 'Workethics',
          'first_name': 'June',
          'surname': 'Green',
          'role': 'Billing'
        },{
          'id': '100306',
          'username': 'QATI',
          'first_name': 'Jim',
          'surname': 'White',
          'role': 'Account Manager'
        },{
          'id': '100307',
          'username': 'TeleOps_2',
          'first_name': 'Jarvis',
          'surname': 'Smith',
          'role': 'Owner'
        },{
          'id': '100308',
          'username': 'TeleOps_3',
          'first_name': 'Jenny',
          'surname': 'Jackson',
          'role': 'Owner'
        },
      ],
    };
  },
  mounted() {
    this.svgHelper();
  },
  methods: {}
}
</script>
<style lang="scss" scoped src="../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../assets/styles/user_profile_details.scss"></style>
