<template>
  <div id="organization-details" class="organization_details">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>
    <div class="d-wrapper">
      <div class="d-form">
        <div class="d-form__title">
          <div class="d-form__title-wrap">
            Organization Details
          </div>
        </div>
        <div class="two-parts-form-wrapper">
          <div class="d-form__body">
            <div class="billing-title"></div>
            <div class="d-form-group">
              <label class="d-form-label">Company Name</label>
              <div class="d-form-content">
                <input class="control" type="text" value="Money LTD.">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">Company Web site</label>
              <div class="d-form-content">
                <input class="control" type="text" value="john-doe.com">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">VAT / TAX ID</label>
              <div class="d-form-content">
                <input class="control" type="text" value="5461618484">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">Phone Number</label>
              <div class="d-form-content">
                <input class="control" type="text" value="+81 5256525225">
              </div>
            </div>
            <div class="billing-title">Company Address</div>
            <div class="d-form-group">
              <label class="d-form-label">Country</label>
              <div class="d-form-content">
                <input class="control" type="text" value="Japan">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">State/Province</label>
              <div class="d-form-content">
                <input class="control" type="text" value="---">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">Street Address</label>
              <div class="d-form-content">
                <input class="control" type="text" value="1-7-1 Kohan Minato-ku, Tokyo">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">Zip Code</label>
              <div class="d-form-content">
                <input class="control" type="text" value="88464321">
              </div>
            </div>
          </div>
          <div class="d-form__body d-form__body-right">
            <div class="d-form-group caption-group">
              <div class="billing-title">Billing address same as company address</div>
              <div class="d-form-group__check">
                <div class="d-form-group__check-text">
                  <label class="container-checkbox">
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">Country</label>
              <div class="d-form-content">
                <input class="control" type="text" value="Japan">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">State/Province</label>
              <div class="d-form-content">
                <input class="control" type="text" value="---">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">Street Address</label>
              <div class="d-form-content">
                <input class="control" type="text" value="1-7-1 Kohan Minato-ku, Tokyo">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">Zip Code</label>
              <div class="d-form-content">
                <input class="control" type="text" value="88464321">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">Notifications are sent to:</label>
              <div class="d-form-content">
                <input class="control" type="text" value="Johndoe@gmail.com">
              </div>
            </div>
            <div class="d-form-group">
              <label class="d-form-label">Invoices are sent to:</label>
              <div class="d-form-content">
                <input class="control" type="text" value="Johndoe@gmail.com">
              </div>
            </div>
          </div>
        </div>
        <div class="d-form__action">
          <div class="btn activate-button center">
            Update
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Breadcrumbs from "./controls/breadcrumbs";

export default {
  components: {Breadcrumbs},
  name: 'OrganizationDetails',
  props: {},
  data() {
    return {
      showModal: false,
      breadcrumbs: [
        {
          "name": "Dashboard",
          "link": "/dashboard",
          "active": false
        },
        {
          "name": "Organization Details",
          "link": "#",
          "active": true
        }
      ],
    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../assets/styles/settings.scss"></style>
