<template>
  <div id="login">
    <form class="form-login">
      <div class="form-login__left-side">
          <img src="../assets/site_icon.png"/>
          <div class="form-login__left-side-text">
            Welcome to your <br/>
            Nemesysco's cloud space
            <div class="form-login__left-side-text-b">
              Your Site
            </div>
          </div>
      </div>
      <div class="form-login__right-side">
        <router-link to="/register">
          <div class="register-button">
            Register
          </div>
        </router-link>
        <div class="form-login-data-wrap">
          <div class="form-login-data-title">Hello! Welcome back.</div>
          <div class="login-sub-text">
            Log in with the credentials you entered during your registration
          </div>
          <div class="login-group">
            <label for="input-login"></label>
            <input id="input-login" type="text" class="login-control" placeholder="Email Address" v-model="email">
          </div>
          <div class="forgot-password">Forgot Password</div>
          <div class="login-group login-group-password">
            <label></label>
            <div class="password-display"  @click="toggleShow">
              <span :class="!showPassword ? 'password-visible' : 'password-invisible'">
                <img class="svg" src="../assets/icons/eye-close.svg"/>
              </span>
              <span :class="showPassword ? 'password-visible' : 'password-invisible'">
                <img class="svg" src="../assets/icons/eye.svg"/>
              </span>
            </div>
            <input v-if="showPassword" type="text" class="login-control" v-model="password" placeholder="Password" />
            <input v-else type="password" class="login-control" v-model="password" placeholder="Password">
          </div>
          <button v-on:click="loginDashboard" class="login-submit">Login</button>
        </div>
        <div class="login-copyright">
          <a href="https://github.com/Nemesysco/LVA-Sites" target="_blank">
            <img class="svg" src="../assets/icons/question-mark.svg">
            Nemesysco cloud - All you need to know
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import router from "@/routes";
import { useUserStore } from '@/stores/userStore';

export default {
  components: {},
  name: 'Login',
  props: {},
  data() {
    return {
      loading: false,
      showPassword: false,
      password: null,
      email: ''
    }
  },
  setup(){
    const userStore = useUserStore();
    return {
      userStore,
    }
  },
  mounted() {
    this.loading = true;
    this.svgHelper();
    this.userStore.loadUser().then((resp) => {
      if (resp.data.statusCode === 401) {
        router.push('/login');
      }
      router.push('/dashboard');
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    loginDashboard(e) {
      e.preventDefault();
      e.stopPropagation();
      this.userStore.login({
        'Email': this.email,
        'Password' : this.password
      })
      .then(() => {
        router.push('/dashboard');
      });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  }
}
</script>

<style scoped lang="scss" src="../assets/styles/authorization.scss"></style>
