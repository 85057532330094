<template>
  <div class="company_details">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>
    <div class="d-wrapper">
      <div class="d-form">
        <div class="d-form__title">
          <div class="d-form__title-wrap">
            Company Details
          </div>
        </div>
        <Tabs :tabs="tabs"></Tabs>
      </div>
    </div>
  </div>
</template>
<script>

import Breadcrumbs from "../controls/breadcrumbs";
import Tabs from "../shared/tabs";
import CompanyProfile from "./admin_company_details_tabs/company_profile";
import Users from "./admin_company_details_tabs/users";
import Sites from "./admin_company_details_tabs/sites";
import PaymentMethods from "./admin_company_details_tabs/payment_methods";
import Invoices from "./admin_company_details_tabs/invoices";

import { shallowRef } from 'vue';
const CompanyProfileRef = shallowRef(CompanyProfile);
const UsersRef = shallowRef(Users);
const SitesRef = shallowRef(Sites);
const PaymentMethodsRef = shallowRef(PaymentMethods);
const InvoicesRef = shallowRef(Invoices);

export default {
  components: {Breadcrumbs, Tabs},
  name: 'CompanyDetails',
  props: {},
  data() {
    return {
      breadcrumbs: [
        {
          "name": "Dashboard",
          "link": "/dashboard",
          "active": false
        },{
          "name": "Companies",
          "link": "/admin/companies",
          "active": false
        },{
          "name": "Company Details",
          "link": "/admin/company/details",
          "active": true
        }
      ],
      tabs: [
        {
          title: "Company Profile",
          component: CompanyProfileRef,
          data: {},
        },{
          title: "Sites",
          component: SitesRef,
          data: {},
        },{
          title: "Users",
          component: UsersRef,
          data: {},
        },{
          title: "Payment Methods",
          component: PaymentMethodsRef,
          data: {},
        },{
          title: "Invoices",
          component: InvoicesRef,
          data: {},
        }
      ]
    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../assets/styles/admin/company_details.scss"></style>

