<template>
    <div id="site-create">
        <breadcrumbs :items="breadcrumbs"></breadcrumbs>
        <div class="d-wrapper">
            <div class="d-form">
                <div class="d-form__title">
                    <div class="d-form__title-wrap">
                        Create New Site
                    </div>
                    <router-link class="btn-cross" to="/dashboard">
                      <img class="svg" src="../assets/icons/close.svg"/>
                    </router-link>
                </div>
                <div class="d-form__body">
                    <div class="d-form-group">
                        <label class="d-form-label">Site Name</label>
                        <div class="d-form-content">
                          <Popper arrow hover placement="top">
                            <input class="control" type="text" v-model="site.name">
                            <template #content>
                              <div>Choose a unique site name.</div>
                              <div>Once set up, this site’s name will appear in your dashboard’s sites table.</div>
                            </template>
                          </Popper>
                        </div>
                    </div>
                    <div class="d-form-group">
                        <label class="d-form-label">Site Password</label>
                        <div class="d-form-content">
                          <Popper arrow hover placement="top">
                            <input class="control" type="password" v-model="site.password">
                            <template #content>
                              <div>Please assign a strong password for your site.</div>
                            </template>
                          </Popper>
                        </div>
                    </div>
                    <div class="d-form-group">
                        <label class="d-form-label">Repeat Password</label>
                        <div class="d-form-content">
                          <input class="control" type="password" v-model="site.repeatPassword">
                        </div>
                    </div>
                    <div class="d-form-group">
                        <label class="d-form-label">Remote Site IP</label>
                        <div class="d-form-content">
                          <Popper arrow hover placement="top">
                            <input class="control" type="text" v-model="site.siteIP">
                            <template #content>
                              <div>Use this field to input the IP address of the computer where this site is, or will be installed.</div>
                            </template>
                          </Popper>
                        </div>
                    </div>
                    <div class="d-form-group">
                        <label class="d-form-label">Application type</label>
                        <div class="d-form-content">
                          <Popper arrow hover placement="top">
                            <Multiselect v-model="site.appType" :options="appType"/>
                            <template #content>
                              <div>Please select the type of LVA service required for this site.</div>
                              <div>For information about the different services, please click XXXX.</div>
                            </template>
                          </Popper>
                        </div>
                    </div>
                    <div class="d-form-group">
                        <label class="d-form-label">No. of concurrent<br/> Channels</label>
                        <div class="d-form-content">
                          <Popper arrow hover placement="top">
                            <input class="control" type="text" v-model="site.channels">
                            <template #content>
                              Please input the number of concurrent channels that will be processed by this site.<br/>
                              A channel refers to an audio channel dedicated to a single speaker.<br/>
                              For example, when analyzing two speakers on a call in real-time – two concurrent channels are required.
                            </template>
                          </Popper>
                        </div>
                    </div>
                    <div class="d-form-group">
                        <label class="d-form-label">Country</label>
                        <div class="d-form-content">
                          <Popper arrow hover placement="top">
                            <Multiselect v-model="site.country" :options="countries"/>
                            <template #content>
                              Please enter the country where this site will be set up.
                            </template>
                          </Popper>
                        </div>
                    </div>
                    <div class="d-form-group">
                        <label class="d-form-label">Type of use</label>
                        <div class="d-form-content">
                          <Popper arrow hover placement="top">
                            <Multiselect v-model="site.type" :options="types"/>
                            <template #content>
                              Please indicate the type of use intended for this site.
                            </template>
                          </Popper>
                        </div>
                    </div>
                    <div class="d-form-group__check">
                        <div class="d-form-group__check-text">
                            <label class="container-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                            <div>
                                Share anonymized data with Nemesysco
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-form__action">
                    <div v-on:click="show()" class="btn activate-button center">
                        Activate
                    </div>
                </div>
                <div class="d-form__footer">
                    <div class="d-form__footer-wrap">
                        <div class="form-progress-wrapper">
                            <div class="progress-item progress-item__first"></div>
                            <div class="progress-item progress-item__second"></div>
                            <div class="progress-item progress-item__third"></div>
                            <div class="progress-item progress-item__fourth"></div>
                            <div class="progress-item progress-item__fifth"></div>
                        </div>
                        <div class="form-progress-description">
                            Creating site credentials
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :visible="showModal" :close="() => {this.showModal = false}" className="info">
            <div class="modal-icon">
                <img class="svg" src="../assets/icons/error-message.svg"/>
            </div>
            <div class="modal-title">
                OOPS!<br/>
                Something went wrong…Please try again
            </div>
            <div class="modal-description">
                Reason for error : lorem ipsum…
            </div>
            <div v-on:click="() => {this.showModal = false}" class="btn btn-close">
                Close
            </div>
        </modal>
    </div>
</template>

<script>

    import Breadcrumbs from "./controls/breadcrumbs";

    export default {
        components: {Breadcrumbs},
        name: 'SiteCreate',
        props: {},
        data() {
            return {
                showModal: false,
                breadcrumbs: [
                    {
                        "name": "Dashboard",
                        "link": "/dashboard",
                        "active": false
                    },
                    {
                        "name": "Create New Site",
                        "link": "#",
                        "active": true
                    }
                ],
                site: {
                    name: '',
                    password: '',
                    repeatPassword: '',
                    siteId: '',
                    siteIP: '',
                    channels: '',
                    appType: null,
                    seats: '56',
                    country: null,
                    type: null
                },
                appType: [
                  { value: '1', label: 'QA7' },
                  { value: '2', label: 'ITN' },
                  { value: '3', label: 'ITR' },
                  { value: '4', label: 'ITZ' },
                  { value: '5', label: 'ITF' },
                ],
                countries: [
                    { value: '1', label: 'Israel' },
                    { value: '2', label: 'Spain' },
                    { value: '3', label: 'USA' },
                ],
                types: [
                    { value: '1', label: 'Technology' },
                    { value: '2', label: 'Telecommunications' },
                    { value: '3', label: 'Health Care' },
                    { value: '4', label: 'Financials' },
                    { value: '5', label: 'Real Estate' },
                    { value: '6', label: 'Consumer Discretionary' },
                    { value: '7', label: 'Consumer Staples' },
                    { value: '8', label: 'Industrials' },
                    { value: '9', label: 'Basic Materials' },
                    { value: '10', label: 'Energy' },
                    { value: '11', label: 'Utilities' },
                ],
            };
        },
        mounted() {
            this.svgHelper()
        },
        methods: {
            show: function () {
              this.showModal = true;
            },
            hide: function () {
                this.showModal = false;
            },
            save: function () {
            },
            cancel: function () {

            },
        }
    }
</script>
<style lang="scss" scoped src="../assets/styles/site.scss"></style>
<style lang="scss" scoped src="../assets/styles/components.scss"></style>

