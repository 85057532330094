<template>
  <div class="grid-container tab licenses">
    <div class="row-navigation">
      <div class="row-navigation-actions">
        <div class="btn btn-add" @click="() => {this.showModalAdd = true}">
          <span>+</span> Add
        </div>
      </div>
      <div>
        <div class="d-form-group">
          <label class="d-form-label">Complimentary free credits</label>
          <div class="d-form-content input-symbol-dollar">
            <input class="control" @blur="showCreditFieldModal" type="text" v-model="creditField.newValue">
          </div>
        </div>
      </div>
    </div>
    <table class="grid-table">
      <thead>
      <th :key="header" v-for="header in grid_heads">
        <div class="grid-header-data">
          {{ header.label }}
        </div>
      </th>
      </thead>
      <tbody>
      <tr :key="key" v-for="(row, key) in grid_rows">
        <td class="center">{{ row.account_type }}</td>
        <td>{{ row.name }}</td>
        <td>{{ row.credit_limit }}</td>
        <td>{{ row.suspension_point }}</td>
        <td>{{ row.royalty_percentage }}</td>
        <td>{{ row.discount_rate }}</td>
        <td>{{ row.free_monthly_credits }}</td>
      </tr>
      </tbody>
    </table>
    <modal :visible="showModalAdd" :close="closeAddItemModal" className="form">
      <div class="header">
        <div v-on:click="closeAddItemModal" class="btn-cross"><img class="svg" src="../../../assets/icons/close.svg"/></div>
        <template v-if="addItem.id">
          <div class="title">
            License
          </div>
          <div class="subtitle">
            License id #{{ addItem.id }}
          </div>
        </template>
        <template v-else>
          <div class="title">
            Add License
          </div>
        </template>
      </div>
      <div class="body d-form">
        <div class="d-form-group">
          <label class="d-form-label">Account Type</label>
          <div class="d-form-content">
            <input class="control" type="text" v-model="addItem.account_type">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">License name</label>
          <div class="d-form-content">
            <input class="control" type="text" v-model="addItem.name">
          </div>
        </div>
        <div class="d-form-group">
          <div class="d-form-label">Credit Limit</div>
          <div class="d-form-content">
            <input class="control" type="text" v-model="addItem.credit_limit">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Suspension Point</label>
          <div class="d-form-content">
            <input class="control" type="text" v-model="addItem.suspension_point">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Royalty Percentage</label>
          <div class="d-form-content">
            <input class="control" type="text" v-model="addItem.royalty_percentage">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Discount Rate</label>
          <div class="d-form-content">
            <input class="control" type="text" v-model="addItem.discount_rate">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Free monthly credits ($)</label>
          <div class="d-form-content">
            <input class="control" type="text" v-model="addItem.free_monthly_credits">
          </div>
        </div>
        <div class="footer">
          <div v-on:click="saveItem" class="btn">
            Save
          </div>
          <div v-on:click="closeAddItemModal" class="btn">
            Close
          </div>
        </div>
      </div>
    </modal>
    <modal :visible="creditFieldModal" :close="() => {this.creditFieldModal = false}" className="dialog">
      <div @click="() => {this.creditFieldModal = false}" class="btn-cross"><img class="svg" src="../../../assets/icons/close.svg"/></div>
      <div class="header">
        <img class="svg" src="../../../assets/icons/error-message.svg"/>
      </div>
      <div class="body">
        This action will change the amount of free credits provided to all new organizations. Are you sure?
      </div>
      <div class="footer">
        <div @click="creditFieldAbort" class="btn btn-ok">
          Abort
        </div>
        <div @click="creditFieldApply" class="btn btn-ok">
          Apply
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

export default {
  components: {},
  props: {},
  data() {
    return {
      creditFieldModal: false,
      creditField: {
        oldValue: '50',
        newValue: '50'
      },
      showModalAdd: false,
      addItem: {},
      grid_heads: [
        {sortable: true, label: 'Account Type'},
        {sortable: true, label: 'License Name'},
        {sortable: true, label: 'Credit Limit'},
        {sortable: true, label: 'Suspension Point'},
        {sortable: true, label: 'Royalty Percentage'},
        {sortable: true, label: 'Discount Rate'},
        {sortable: true, label: 'Free monthly credits ($)'},
      ],
      grid_rows: [
        {
          'id': 0,
          'account_type': 0,
          'name': 'DEV',
          'editable': true, //?
        },{
          'id': 1,
          'account_type': 1,
          'name': 'Pre-paid',
          'editable': true,
        },{
          'id': 2,
          'account_type': 2,
          'name': 'SILVER',
          'editable': true,
        },{
          'id': 3,
          'account_type': 3,
          'name': 'GOLD',
          'editable': true,
        },{
          'id': 4,
          'account_type': 4,
          'name': 'PLATINUM',
          'editable': true,
        },{
          'id': 5,
          'account_type': 5,
          'name': 'NEMESYSCO ONLY',
          'editable': false,
        },
      ],
    };
  },
  mounted() {
    this.svgHelper();
  },
  methods: {
    showCreditFieldModal(){
      if(this.creditField.newValue !== this.creditField.oldValue) this.creditFieldModal = true;
    },
    creditFieldAbort(){
      this.creditFieldModal = false;
      this.creditField.newValue = this.creditField.oldValue;
    },
    creditFieldApply(){
      this.creditFieldModal = false;
      this.creditField.oldValue = this.creditField.newValue;
    },
    closeAddItemModal: function(){
      this.showModalAdd = false;
      this.addItem = {};
    },
    saveItem: function () {
      if(!this.addItem.id){
        this.addItem.id = this.grid_rows.length > 0 ? (this.grid_rows[this.grid_rows.length - 1].id + 1) : 1;
        this.addItem.editable = true;
        this.grid_rows.push(this.addItem);
      }
      else{
        const license = this.grid_rows.find((item) => item.id === this.addItem.id);
        Object.assign(license, this.addItem);
      }

      this.addItem = {};
      this.showModalAdd = false;

      setTimeout(() => {this.svgHelper();}, 0);
    },
    openItem: function (id){
      this.addItem = {...this.grid_rows.find((item) => item.id === id)};
      this.showModalAdd = true;
    },
  }
}
</script>
<style lang="scss" scoped src="../../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../../assets/styles/admin/settings.scss"></style>
