<template>
  <div id="login">
    <form class="form-login">
      <div class="form-login__left-side">
          <img src="../assets/site_icon.png"/>
          <div class="form-login__left-side-text">
            Welcome to your <br/>
            Nemesysco's cloud space
            <div class="form-login__left-side-text-b">
              Your Site
            </div>
          </div>
      </div>
      <div class="form-login__right-side">
        <div class="form-login-data-wrap">
          <div class="form-login-data-title">Create new password.</div>
          <div class="login-sub-text">
            Your new password must be different from previous used password.
          </div>
          <div class="login-group login-group-password">
            <label></label>
            <div class="password-display"  @click="toggleShow">
              <span :class="!showPassword ? 'password-visible' : 'password-invisible'">
                <img class="svg" src="../assets/icons/eye-close.svg"/>
              </span>
              <span :class="showPassword ? 'password-visible' : 'password-invisible'">
                <img class="svg" src="../assets/icons/eye.svg"/>
              </span>
            </div>
            <input :type="showPassword ? 'text' : 'password'" class="login-control" v-model="password" placeholder="New Password" />
          </div>
          <div class="login-group login-group-password">
            <label></label>
            <input type="password" class="login-control" v-model="repeat_password" placeholder="Repeat New Password" />
          </div>
          <button v-on:click="loginDashboard" class="login-submit">Reset Password</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'Login',
  props: {},
  data() {
    return {
      showPassword: false,
      password: null,
      repeat_password: null,
      userName: ''
    }
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    loginDashboard(e) {
      e.preventDefault();
      e.stopPropagation();
      this.axios.post('https://nmsidpprovider.azurewebsites.net/auth/login',
      {'userName': this.userName, 'password' : this.password},
          { headers:
           {
              'Content-Type': 'application/json'
           }})
      .then(function(result) {
        console.log(result);
      });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    redirectRegister() {
      window.location.href ='https://nmsnewusers.azurewebsites.net/';
      //this.$router.push('/register');
    }
  }
}
</script>

<style scoped lang="scss" src="../assets/styles/authorization.scss"></style>
