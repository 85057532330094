<template>
  <div class="grid-container tab">
    <div class="row-navigation">
      <div class="row-navigation-actions">
        <div class="btn btn-add" @click="() => {this.showModalAdd = true}">
          <span>+</span> Add
        </div>
      </div>
    </div>
    <table class="grid-table short-table">
      <thead>
      <th :key="header" v-for="header in grid_heads">
        <div class="grid-header-data">
          {{ header.label }}
        </div>
      </th>
      </thead>
      <tbody>
      <tr :key="key" v-for="(row, key) in grid_rows">
        <td>{{ row.name }}</td>
        <td class="quick-actions">
          <div class="action" @click="() => openItem(row.id)">
            <img class="svg" src="../../../assets/icons/folder.svg"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <modal :visible="showModalAdd" :close="closeAddItemModal" className="form">
      <div class="header">
        <div v-on:click="closeAddItemModal" class="btn-cross"><img class="svg" src="../../../assets/icons/close.svg"/></div>
        <template v-if="addItem.id">
          <div class="title">
           Type of use
          </div>
          <div class="subtitle">
            Type of use id #{{addItem.id}}
          </div>
        </template>
        <template v-else>
          <div class="title">
            Add Type of use
          </div>
        </template>
      </div>
      <div class="body d-form">
        <div class="d-form-group">
          <label class="d-form-label">Type of use name:</label>
          <div class="d-form-content">
            <input class="control" type="text" v-model="addItem.name">
          </div>
        </div>
        <div class="footer">
          <div v-on:click="saveItem" class="btn">
            Save
          </div>
          <div v-on:click="closeAddItemModal" class="btn">
            Close
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

export default {
  components: {},
  props: {},
  data() {
    return {
      showModalAdd: false,
      addItem: {},
      grid_heads: [
        {sortable: true, label: 'Type of use'},
        {sortable: false, label: ''},
      ],
      grid_rows: [
        {
          'id': 1,
          'name': 'Technology',
        },{
          'id': 2,
          'name': 'Telecommunication',
        },{
          'id': 3,
          'name': 'Health Care',
        },{
          'id': 4,
          'name': 'Financial',
        },{
          'id': 5,
          'name': 'Real Estate',
        },{
          'id': 6,
          'name': 'Consumer Discretionary',
        },{
          'id': 7,
          'name': 'Industrials',
        },{
          'id': 8,
          'name': 'Basic Materials',
        },
      ],
    };
  },
  mounted() {
    this.svgHelper();
  },
  methods: {
    closeAddItemModal: function(){
      this.showModalAdd = false;
      this.addItem = {};
    },
    saveItem: function () {
      if(!this.addItem.id){
        this.addItem.id = this.grid_rows.length > 0 ? (this.grid_rows[this.grid_rows.length - 1].id + 1) : 1;
        this.addItem.editable = true;
        this.grid_rows.push(this.addItem);
      }
      else{
        const oldItem = this.grid_rows.find((item) => item.id === this.addItem.id);
        Object.assign(oldItem, this.addItem);
      }

      this.addItem = {};
      this.showModalAdd = false;

      setTimeout(() => {this.svgHelper();}, 0);
    },
    openItem: function (id){
      this.addItem = {...this.grid_rows.find((item) => item.id === id)};
      this.showModalAdd = true;
    },
  }
}
</script>
<style lang="scss" scoped src="../../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../../assets/styles/admin/settings.scss"></style>
