<template>
  <div id="billing-overview" class="billing-form">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>
    <div class="d-wrapper">
      <div class="d-form">
        <div class="d-form__title">
          <div class="d-form__title-wrap">
            Billing Overview
          </div>
        </div>
        <div class="d-form__body">
          <div class="billing-title">Details and Current balance</div>
          <div class="d-form-group">
            <label class="d-form-label">Account type</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="Silver">
            </div>
          </div>
          <div class="d-form-group">
            <label class="d-form-label">Billing type</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="Automatic">
            </div>
          </div>
          <div class="d-form-group">
            <label class="d-form-label">Payment due</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="$912.10">
            </div>
            <div class="d-form-info billing-info-invoice">
              Invoice #998238
            </div>
          </div>
          <div class="d-form-group">
            <label class="d-form-label">Will be Automatically charged on:</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="01/02/2021">
            </div>
            <div class="d-form-info">
                <div class="btn btn-min">
                  Pay now
                </div>
            </div>
          </div>
          <div class="billing-title">Payment details</div>
          <div class="d-form-group">
            <label class="d-form-label">Credit card:</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="XXXX-XXXX-XXXX-3345">
            </div>
            <div class="d-form-info">
              <div class="btn btn-min">
                Update details
              </div>
            </div>
          </div>

          <div class="settings-form">
            <div class="setting-separator"></div>
            <div class="settings-form__group">
              <label class="settings-form-title-block">Send notification</label>
              <div class="settings-form-control">
                <Toggle v-model="sendNotification">
                  <template v-slot:label="{ checked, classList }">
                    <span :class="classList.label">
                      <span v-if="checked"><img src="../assets/icons/check.png"/></span>
                      <span v-else>&nbsp;</span>
                    </span>
                  </template>
                </Toggle>
              </div>
            </div>
            <div class="settings-form__group">
              <label class="settings-form-title">Balance amount (USD)</label>
              <div class="settings-form-control">
                <input type="text" value="0.0">
              </div>
            </div>
            <div class="setting-separator"></div>
            <div class="settings-form__group">
              <label class="settings-form-title-block">Send low balance notifications to</label>
            </div>
            <div class="settings-form__group">
              <label class="settings-form-title">Email Address</label>
              <div class="settings-form-control">
                <input type="text" value="example@gmail.com">
              </div>
            </div>
            <div class="settings-form__group">
              <label class="settings-form-title">Mobile</label>
              <div class="settings-form-control">
                <input type="text" value="+972 52 2222222">
              </div>
            </div>
          </div>
          <div class="d-form__action">
            <div class="btn activate-button">
              Submit
            </div>
          </div>
        </div>
      </div>
      <div class="chart-container billing-form-chart">
        <div class="chart-header-container">
          <div class="chart-title">
            Invoice Breakdown by Month
          </div>
          <div class="chart-btn-group">
            <div class="btn">Charge</div>
            <div class="chart-datepicker">
              <Datepicker v-model="date" :enableTimePicker="false" range twoCalendars position="right" menuClassName="datepicker-position-right"></Datepicker>
            </div>
          </div>
        </div>
        <v-chart class="chart" :option="payments"/>
      </div>
    </div>
    <div class="grid-container billing-table">
      <div class="row-navigation">
        <div class="title">Recent Transactions</div>
        <div class="row-navigation-exports">
          <div class="export-button">
            <img class="svg" src="../assets/icons/pdf.svg"/>
          </div>
          <div class="export-button">
            <img class="svg" src="../assets/icons/csv.svg"/>
          </div>
          <div class="export-button">
            <img class="svg" src="../assets/icons/json-file.svg"/>
          </div>
        </div>
      </div>
      <table class="grid-table">
        <thead>
        <th class="checkbox-th">
          <label class="container-checkbox">
            <input type="checkbox" checked="checked">
            <span class="checkmark"></span>
          </label>
        </th>
        <th :key="header" v-for="header in grid_heads">
          <div class="grid-header-data">
            {{ header.label }}
            <div v-if="header.sortable" class="grid-sort-order">
              <div class="triangle-up"></div>
              <div class="triangle-down"></div>
            </div>
          </div>
        </th>
        </thead>
        <tbody>
        <tr :key="key" v-for="(row, key) in grid_rows">
          <td class="checkbox-tr">
            <label class="container-checkbox">
              <input type="checkbox">
              <span class="checkmark"></span>
            </label>
          </td>
          <td>{{ row.invoiceId }}</td>
          <td>{{ row.invoiceDate }}</td>
          <td>{{ row.totalSites }}</td>
          <td>{{ row.totalDates }}</td>
          <td>{{ row.totalMinutes }}</td>
          <td>{{ row.invoiceTotal }}</td>
          <td>
              <span v-if="row.typeStatus === '1'" class="grid-status-label grid-status-label__success">
                {{ row.status }}
              </span>
              <span v-if="row.typeStatus === '2'" class="grid-status-label grid-status-label__active">
                {{ row.status }}
              </span>
              <span  v-if="row.typeStatus === '3'" class="grid-status-label grid-status-label__suspended">
                  {{ row.status }}
              </span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import Breadcrumbs from "./controls/breadcrumbs";
import Datepicker from 'vue3-date-time-picker';
import {BarChart, PieChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent, GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import Toggle from '@vueform/toggle';

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {Breadcrumbs, VChart, Datepicker, Toggle},
  name: 'BillingOverview',
  props: {},
  data() {
    return {
      sendNotification: false,
      showModal: false,
      breadcrumbs: [
        {
          "name": "Dashboard",
          "link": "/dashboard",
          "active": false
        },
        {
          "name": "Overview",
          "link": "#",
          "active": true
        }
      ],
      date: null,
      payments: {
        tooltip: {
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: ['6/21', '7/21', '8/21', '9/21', '10/21', '11/21', '12/21'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [100, 1000, 2000, 2500, 1800, 2500, 1100],
            type: 'bar',
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },

          }
        ],
        color: '#77b5ff'
      },
      billing: {},
      grid_heads: [
        {sortable: false, label: 'Invoice ID'},
        {sortable: false, label: 'Invoice Date'},
        {sortable: false, label: 'Total active sites'},
        {sortable: false, label: 'Total days charged'},
        {sortable: true, label: 'Total minutes charged'},
        {sortable: true, label: 'Invoice total'},
        {sortable: true, label: 'Status'}
      ],
      grid_rows: [
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'Paid',
          'typeStatus': '1'
        },
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'Due',
          'typeStatus': '2'
        },
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'Due',
          'typeStatus': '2'
        },
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'OVERDUE',
          'typeStatus': '3'
        },
        {
          'invoiceId': '034534',
          'invoiceDate': '1.12.21',
          'totalSites': '5',
          'totalDates': '$2.855',
          'totalMinutes': '0',
          'invoiceTotal': '$59.460',
          'status': 'Paid',
          'typeStatus': '1'
        },
      ],
    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../assets/styles/billing.scss"></style>

