<template>
  <div class="user_profile_details">
    <div class="d-wrapper">
      <div class="d-form">
        <div class="d-form__title">
          <div class="d-form__title-wrap">
            User Profile Details
          </div>
        </div>
        <Tabs :tabs="tabs"></Tabs>
      </div>
    </div>
  </div>
</template>
<script>

import Tabs from "./shared/tabs";
import UserProfile from "./user_profile_details_tabs/user_profile";
import Users from "./user_profile_details_tabs/users";
import ApiKeys from "./user_profile_details_tabs/api_keys";

import { shallowRef } from 'vue';
const UserProfileRef = shallowRef(UserProfile);
const UsersRef = shallowRef(Users);
const ApiKeysRef = shallowRef(ApiKeys);

export default {
  components: {Tabs},
  name: 'CompanyDetails',
  props: {},
  data() {
    return {
      tabs: [
        {
          title: "User Profile",
          component: UserProfileRef,
          data: {},
        },{
          title: "Account",
          component: 'div',
          data: {},
        },{
          title: "Users",
          component: UsersRef,
          data: {},
        },{
          title: "Security",
          component: 'div',
          data: {},
        },{
          title: "Payment Method",
          component: 'div',
          data: {},
        },{
          title: "Notifications",
          component: 'div',
          data: {},
        },{
          title: "Api Keys",
          component: ApiKeysRef,
          data: {},
        }
      ]
    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../assets/styles/user_profile_details.scss"></style>

