<template>
  <div class="tab">
    <div class="header">
      <div class="tabBlock-tabs">
        <div
            v-for="(tab, index) in tabs"
            :key="index"
            :aria-setsize="tabs.length"
            :aria-posinet="index + 1"
            class="tabBlock-tab-container"
            :style="styledTabWidth(tab.title)"
        >
          <a
              href="javascript:void(0);"
              class="tabBlock-tab"
              :class="active_tab === index ? 'is-active' : ''"
              :aria-selected="active_tab === index"
              @click="changeTab(index)"
          >
            {{ tab.title }}
          </a>
        </div>
      </div>
    </div>

    <div class="tabBlock-content">
      <div
          v-for="(tab, index) in tabs"
          :key="index"
          :aria-current="active_tab === index"
          v-show="active_tab === index"
      >
        <component v-bind:is="tab.component"></component>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  name: 'Tabs',
  props: ['tabs'],
  data() {
    return {
      showModal: false,
      active_tab: 0,
    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    styledTabWidth(tabName){
      return {
        '--char-count': tabName.length
      }
    },
    changeTab(tabIndexValue) {
      this.active_tab = tabIndexValue;
    }
  }
}
</script>

