<template>
  <div class="grid-container users">
    <div class="row-navigation">
      <div class="row-navigation-actions">
        <div class="grid-search-wrapper">
          <div class="global-search-icon">
            <img class="svg" src="../../../assets/icons/grid-search.svg"/>
          </div>
          <input class="grid-search" type="text" value="" placeholder="Search User">
        </div>
      </div>
      <div class="row-navigation-exports">
        <div class="export-button">
          <img class="svg" src="../../../assets/icons/pdf.svg"/>
        </div>
        <div class="export-button">
          <img class="svg" src="../../../assets/icons/csv.svg"/>
        </div>
        <div class="export-button">
          <img class="svg" src="../../../assets/icons/json-file.svg"/>
        </div>
      </div>
    </div>
    <table class="grid-table">
      <thead>
      <th class="checkbox-th">
        <label class="container-checkbox">
          <input type="checkbox" checked="checked">
          <span class="checkmark"></span>
        </label>
      </th>
      <th :key="header" v-for="header in grid_heads">
        <div class="grid-header-data">
          {{ header.label }}
          <div v-if="header.sortable" class="grid-sort-order">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
        </div>
      </th>
      </thead>
      <tbody>
      <tr :key="key" v-for="(row, key) in grid_rows">
        <td class="checkbox-tr">
          <label class="container-checkbox">
            <input type="checkbox">
            <span class="checkmark"></span>
          </label>
        </td>
        <td>{{ row.name }}</td>
        <td class="license">{{ row.org_licence }}</td>
        <td>{{ row.last_login }}</td>
        <td>{{ row.user_type }}</td>
        <td class="quick-actions">
          <div class="action">
            <img class="svg" src="../../../assets/icons/enter.svg"/>
          </div>
          <div class="action">
            <img class="svg" src="../../../assets/icons/remove.svg"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>



<script>

export default {
  components: {},
  name: 'Users',
  props: {},
  data() {
    return {
      grid_heads: [
        {sortable: true, label: 'User Name'},
        {sortable: true, label: 'Org Licence'},
        {sortable: true, label: 'Last Login'},
        {sortable: true, label: 'User Type'},
        {sortable: false, label: 'Quick Actions'},
      ],
      grid_rows: [
        {
          'name': 'Customer 000',
          'org_licence': 'dev',
          'last_login': '12.12.21',
          'user_type': 'OWNER'
        },
        {
          'name': 'Customer 001',
          'org_licence': 'Silver',
          'last_login': '12.12.21',
          'user_type': 'IT'
        },
        {
          'name': 'Customer 002',
          'org_licence': 'dev',
          'last_login': '12.12.21',
          'user_type': 'FINANCE'
        },
        {
          'name': 'Customer 003',
          'org_licence': 'gold',
          'last_login': '12.12.21',
          'user_type': 'Manager'
        },
        {
          'name': 'Customer 004',
          'org_licence': 'premium',
          'last_login': '12.12.21',
          'user_type': 'Manager'
        },
        {
          'name': 'Customer 005',
          'org_licence': 'dev',
          'last_login': '12.12.21',
          'user_type': 'Manager'
        },
        {
          'name': 'Customer 006',
          'org_licence': 'dev',
          'last_login': '12.12.21',
          'user_type': 'Manager'
        },
      ],
    };
  },
  mounted() {
    this.svgHelper();
  },
  methods: {}
}
</script>
<style lang="scss" scoped src="../../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../../assets/styles/admin/company_details.scss"></style>
