<template>
  <div id="billing-overview" class="grid-container tab billing-form company-details-payment-methods">
    <div class="d-wrapper">
      <div class="d-form">
        <div class="d-form__title">
          <div class="d-form__title-wrap">
            Billing Overview
          </div>
        </div>
        <div class="d-form__body">
          <div class="billing-title">Details and Current balance</div>
          <div class="d-form-group">
            <label class="d-form-label">Account type</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="Silver">
            </div>
          </div>
          <div class="d-form-group">
            <label class="d-form-label">Billing type</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="Automatic">
            </div>
          </div>
          <div class="d-form-group">
            <label class="d-form-label">Payment due</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="$912.10">
            </div>
            <div class="d-form-info billing-info-invoice">
              Invoice #998238
            </div>
          </div>
          <div class="d-form-group">
            <label class="d-form-label">Will be Automatically charged on:</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="01/02/2021">
            </div>
            <div class="d-form-info">
                <div class="btn btn-min">
                  Pay now
                </div>
            </div>
          </div>
          <div class="billing-title">Payment details</div>
          <div class="d-form-group">
            <label class="d-form-label">Credit card:</label>
            <div class="d-form-content">
              <input class="control disabled" type="text" value="XXXX-XXXX-XXXX-3345">
            </div>
            <div class="d-form-info">
              <div class="btn btn-min">
                Update details
              </div>
            </div>
          </div>

          <div class="settings-form">
            <div class="setting-separator"></div>
            <div class="settings-form__group">
              <label class="settings-form-title-block">Send notification</label>
              <div class="settings-form-control">
                <Toggle v-model="sendNotification">
                  <template v-slot:label="{ checked, classList }">
                    <span :class="classList.label">
                      <span v-if="checked"><img src="../../../assets/icons/check.png"/></span>
                      <span v-else>&nbsp;</span>
                    </span>
                  </template>
                </Toggle>
              </div>
            </div>
            <div class="settings-form__group">
              <label class="settings-form-title">Balance amount (USD)</label>
              <div class="settings-form-control">
                <input type="text" value="0.0">
              </div>
            </div>
            <div class="setting-separator"></div>
            <div class="settings-form__group">
              <label class="settings-form-title-block">Send low balance notifications to</label>
            </div>
            <div class="settings-form__group">
              <label class="settings-form-title">Email Address</label>
              <div class="settings-form-control">
                <input type="text" value="example@gmail.com">
              </div>
            </div>
            <div class="settings-form__group">
              <label class="settings-form-title">Mobile</label>
              <div class="settings-form-control">
                <input type="text" value="+972 52 2222222">
              </div>
            </div>
          </div>
          <div class="d-form__action">
            <div class="btn activate-button">
              Submit
            </div>
          </div>
        </div>
      </div>
      <div class="chart-container billing-form-chart">
        <div class="chart-header-container">
          <div class="chart-title">
            Invoice Breakdown by Month
          </div>
          <div class="chart-btn-group">
            <div class="btn">Charge</div>
            <div class="chart-datepicker">
              <Datepicker v-model="date" :enableTimePicker="false" range twoCalendars position="right" menuClassName="datepicker-position-right"></Datepicker>
            </div>
          </div>
        </div>
        <v-chart class="chart" :option="payments"/>
      </div>
    </div>
  </div>
</template>
<script>

import Datepicker from 'vue3-date-time-picker';
import {BarChart, PieChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent, GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import Toggle from '@vueform/toggle';

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {VChart, Datepicker, Toggle},
  props: {},
  data() {
    return {
      sendNotification: false,
      showModal: false,
      date: null,
      payments: {
        tooltip: {
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: ['6/21', '7/21', '8/21', '9/21', '10/21', '11/21', '12/21'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [100, 1000, 2000, 2500, 1800, 2500, 1100],
            type: 'bar',
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },

          }
        ],
        color: '#77b5ff'
      },
      billing: {},

    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../../assets/styles/billing.scss"></style>

