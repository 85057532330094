<template>
  <div class="notifications">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>
    <div class="grid-container">
      <div class="row-navigation">
        <div class="row-navigation-actions">
          <div class="btn btn-add" @click="() => {this.showModalAdd = true}">
            <span>+</span> Add
          </div>
        </div>
      </div>
      <table class="grid-table">
        <thead>
        <th :key="header" v-for="header in grid_heads">
          <div class="grid-header-data">
            {{ header.label }}
          </div>
        </th>
        </thead>
        <tbody>
        <tr :key="key" v-for="(row, key) in grid_rows">
          <td>{{ row.id }}</td>
          <td>{{ row.group }}</td>
          <td>{{ row.subgroup }}</td>
          <td>{{ row.text }}</td>
          <td class="quick-actions">
            <div class="action" @click="() => openItem(row.id)">
              <img class="svg" src="../../assets/icons/folder.svg"/>
            </div>
            <div class="action" @click="() => removeItem(row.id)">
              <img class="svg" src="../../assets/icons/remove.svg"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <modal :visible="showModalAdd" :close="closeAddItemModal" className="form">
      <div class="header">
        <div v-on:click="closeAddItemModal" class="btn-cross"><img class="svg" src="../../assets/icons/close.svg"/></div>
        <template v-if="addItem.id">
          <div class="title">
            Notification
          </div>
          <div class="subtitle">
            Notification id #{{ addItem.id }}
          </div>
        </template>
        <template v-else>
          <div class="title">
            New Notification
          </div>
        </template>
      </div>
      <div class="body d-form">
        <div class="d-form-group">
          <div class="d-form-label">Group</div>
          <div class="d-form-content">
            <Multiselect v-model="addItem.group" :options="groups"/>
          </div>
        </div>
        <div class="d-form-group align-flex-start">
          <div class="d-form-label">Text</div>
          <div class="d-form-content">
            <textarea class="control" v-model="addItem.text"/>
          </div>
        </div>
        <div v-on:click="saveItem" class="btn btn-ok">
          Save
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import Breadcrumbs from "../controls/breadcrumbs";

export default {
  components: {Breadcrumbs},
  props: {},
  data() {
    return {
      showModalAdd: false,
      breadcrumbs: [
        {
          "name": "Dashboard",
          "link": "/dashboard",
          "active": false
        },{
          "name": "Notifications",
          "link": "#",
          "active": true
        },
      ],
      addItem: {},
      groups: [
        { value: 'license', label: 'license' },
        { value: 'Billing', label: 'Billing' },
      ],
      subgroups: [
        { value: 'Expansion', label: 'Expansion' },
        { value: 'Invoices', label: 'Invoices' },
        { value: 'Payment', label: 'Payment' },
      ],
      grid_heads: [
        {sortable: true, label: 'Id'},
        {sortable: true, label: 'Group'},
        {sortable: true, label: 'Subgroup'},
        {sortable: true, label: 'Text'},
        {sortable: false, label: 'Actions'},
      ],
      grid_rows: [
        {
          'id': 1,
          'group': 'license',
          'subgroup': 'Expansion',
          'text': 'Your permission level was updated to level#2',
        },
        {
          'id': 2,
          'group': 'license',
          'subgroup': 'Expansion',
          'text': 'Your request for upgrading your permission level was denied',
        },
        {
          'id': 3,
          'group': 'Billing',
          'subgroup': 'Invoices',
          'text': 'Your monthly invoice is ready',
        },
        {
          'id': 4,
          'group': 'Billing',
          'subgroup': 'Payment',
          'text': 'Your last payment was not proceeded. Please check your payment method',
        },
      ],
    };
  },
  mounted() {
    this.svgHelper();
  },
  methods: {
    closeAddItemModal: function(){
      this.showModalAdd = false;
      this.addItem = {};
    },
    saveItem: function () {
      if(!this.addItem.id){
        this.addItem.id = this.grid_rows.length > 0 ? (this.grid_rows[this.grid_rows.length - 1].id + 1) : 1;
        this.grid_rows.push(this.addItem);
      }
      else{
        const notification = this.grid_rows.find((item) => item.id === this.addItem.id);
        Object.assign(notification, this.addItem);
      }

      this.addItem = {};
      this.showModalAdd = false;

      setTimeout(() => {this.svgHelper();}, 0);
    },
    openItem: function (id){
      this.addItem = {...this.grid_rows.find((item) => item.id === id)};
      this.showModalAdd = true;
    },
    removeItem: function (id){
      this.grid_rows = this.grid_rows.filter((item) => item.id !== id);
    },
  },
}
</script>
<style lang="scss" scoped src="../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../assets/styles/admin/notifications.scss"></style>