<template>
  <div class="header">
    <div class="top-header-wrapper">
      <div class="top-header-wrapper__link" @click="logout">
        Logout
        <svg id="Layer_1" height="16" viewBox="0 0 512 512" width="16" xmlns="http://www.w3.org/2000/svg">
          <path fill="#ffffff" d="m371.553 297.367c-8.836 0-16 7.163-16 16v62.457c0 12.063-9.813 21.876-21.876 21.876h-83.899v-249.946c0-19.768-9.818-36.773-26.938-46.658l-119.678-69.096h230.515c12.063 0 21.876 9.813 21.876 21.876v71.241c0 8.837 7.164 16 16 16s16-7.163 16-16v-71.241c0-29.707-24.169-53.876-53.876-53.876h-252.351c-29.707 0-53.876 24.169-53.876 53.876v337.956c0 19.769 9.818 36.774 26.938 46.659l114.576 66.149c8.561 4.942 17.749 7.414 26.938 7.413 9.188-.001 18.379-2.472 26.937-7.413 17.12-9.883 26.938-26.889 26.938-46.657v-28.283h83.899c29.708 0 53.876-24.169 53.876-53.876v-62.457c.001-8.837-7.162-16-15.999-16zm-153.775 160.616c0 8.145-3.884 14.872-10.938 18.944-7.053 4.072-14.822 4.072-21.875 0l-114.577-66.149c-7.054-4.072-10.938-10.801-10.938-18.946v-337.956c0-3.22.699-6.28 1.954-9.035l145.436 83.968c7.054 4.072 10.938 10.801 10.938 18.945zm262.085-231.819-51.992 51.992c-3.124 3.124-7.219 4.687-11.313 4.687s-8.189-1.563-11.313-4.687c-6.249-6.248-6.249-16.379 0-22.627l24.679-24.679h-147c-8.836 0-16-7.163-16-16s7.164-16 16-16h146.999l-24.678-24.679c-6.249-6.248-6.249-16.379 0-22.627 6.248-6.249 16.379-6.249 22.627 0l51.992 51.992c6.248 6.249 6.248 16.38-.001 22.628z"/>
        </svg>
      </div>
      
    </div>
    <div class="header__data">
      <div class="header__data-left">
        <div class="header-info-block">
          <div>
            <div>Active sites</div>
            <span class="info-data-number info-data-number__blue">5</span>
          </div>
          <div>
            <img class="svg" src="../assets/icons/site-blue.svg"/>
          </div>
        </div>
        <div class="header-info-block">
          <div>
            <div>Error sites</div>
            <span class="info-data-number info-data-number__red">1</span>
          </div>
          <div>
            <img class="svg" src="../assets/icons/site-red.svg"/>
          </div>
        </div>
      </div>
      <div class="header__data-right">
        <div class="header-info-block">
          <div>
            <div>Total Monthly used</div>
            <span class="info-data-number info-data-number__blue">$560</span>
          </div>
        </div>
        <div class="dashboard-header__button btn-green">
          <span class="dashboard-header__button-icon">
            <img class="svg" src="../assets/icons/upgrade.svg"/>
          </span>
          <span>
            Upgrade your account
          </span>
        </div>
        <div class="dashboard-header__button btn-primary">
          <span class="dashboard-header__button-icon">
            <img class="svg" src="../assets/icons/wallet.svg"/>
          </span>
          <span>
           Buy Credits
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/stores/userStore";
import router from "@/routes";

export default {
  name: 'Header',
  props: {
    msg: String
  },
  data() {
    return {
      notifications: [
        {
          date: '12/2',
          message: 'your account is ready',
        },{
          date: '13/1',
          message: 'you received $50 cred it',
        },{
          date: '12/12',
          message: 'Lorem Ipsum is simply',
        },
      ],
    }
  },
  setup(){
    const userStore = useUserStore();
    return {
      userStore,
    }
  },
  mounted() {
  },
  methods: {
    logout(e) {
      e.stopPropagation();
      this.userStore.logout()
      .then(() => {
        router.push('/login');
      });
    }
  }
}
</script>
<style lang="scss" scoped src="../assets/styles/header.scss"></style>
