import {createRouter, createWebHistory} from "vue-router";

import Login from './components/login'
import Register from './components/register'
import ForgotPassword from './components/forgot_password'

import Settings from './components/settings'
import SettingsUsers from './components/users_container'
import SettingsApiKeys from './components/api_keys_container'

import Dashboard from './components/dashboard'
import SiteCreate from './components/site_create'
import SiteForm from './components/site_form'
import BillingOverview from './components/billing_overview'
import BillingPayment from './components/billing_payment'
import UserProfileContainer from './components/user_profile_container'
import OrganizationDetails from './components/organization_details'
import UserProfileDetails from './components/user_profile_details'
import Layout from "./Layout";

//Admin
import Users from './components/admin/users'
import Companies from './components/admin/companies'
import CompanyDetails from './components/admin/company_details'
import AdminBillingPayment from './components/admin/admin_billing_payment'
import AdminSettings from './components/admin/settings'
import Notifications from './components/admin/notifications'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/register',
            name: 'Register',
            component: Register
        },
        {
            path: '/forgotpassword',
            name: 'ForgotPassword',
            component: ForgotPassword
        },
        {
            path: '',
            name: 'base',
            component: Layout,
            redirect: '/dashboard',
            meta: {title: '', icon: ''},
            children: [
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: {title: 'Dashboard'}
                },
                {
                    path: '/settings',
                    name: 'Settings',
                    component: Settings,
                    meta: {title: 'Settings'}
                },
                {
                    path: '/site/create',
                    name: 'SiteCreate',
                    component: SiteCreate,
                    meta: {title: 'Create Site'}
                },
                {
                    path: '/site/form',
                    name: 'SiteForm',
                    component: SiteForm,
                    meta: {title: 'Site Details'}
                },
                {
                    path: '/billing/overview',
                    name: 'BillingOverview',
                    component: BillingOverview,
                    meta: {title: 'Site Details'}
                },
                {
                    path: '/billing/payment',
                    name: 'BillingPayment',
                    component: BillingPayment,
                    meta: {title: 'Site Details'}
                },
                {
                    path: '/user/profile',
                    name: 'User Profile',
                    component: UserProfileContainer,
                    meta: {title: 'User Profile Container'}
                },
                {
                    path: '/organization/details',
                    name: 'OrganizationDetails',
                    component: OrganizationDetails,
                    meta: {title: 'Site Details'}
                },
                {
                    path: '/admin/users',
                    name: 'AdminUsers',
                    component: Users,
                    meta: {title: 'Users'}
                },
                {
                    path: '/admin/companies',
                    name: 'AdminCompanies',
                    component: Companies,
                    meta: {title: 'Site Details'}
                },
                {
                    path: '/admin/company/details',
                    name: 'CompanyDetails',
                    component: CompanyDetails,
                    meta: {title: 'Company details'}
                },
                {
                    path: '/admin/billing/payment',
                    name: 'AdminBillingPayment',
                    component: AdminBillingPayment,
                    meta: {title: 'Admin billing payment'}
                },
                {
                    path: '/admin/settings',
                    name: 'AdminSettings',
                    component: AdminSettings,
                    meta: {title: 'Admin settings'}
                },
                {
                    path: '/user-profile-details',
                    name: 'UserProfileDetails',
                    component: UserProfileDetails,
                    meta: {title: 'User Profile Details'}
                },
                {
                    path: '/admin/notifications',
                    name: 'AdminNotifications',
                    component: Notifications,
                    meta: {title: 'Notifications'}
                },
                {
                    path: '/settings/users',
                    name: 'SettingsUsers',
                    component: SettingsUsers,
                    meta: {title: 'Users'}
                },
                {
                    path: '/settings/api-keys',
                    name: 'SettingsApiKeys',
                    component: SettingsApiKeys,
                    meta: {title: 'ApiKeys'}
                },
            ]
        }
    ]
});

export default router;