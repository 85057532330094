<template>
    <div v-if="!loading" id="app-wrapper">
        <Sidebar></Sidebar>
        <div class="main_content">
            <Header></Header>
            <div class="page_data">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import Sidebar from './components/sidebar.vue'
    import Header from './components/header.vue'
    import {useUserStore} from "@/stores/userStore";
    import router from "@/routes";
    import {ref} from "@vue/reactivity";

    export default {
        name: 'Layout',
        components: {
            Header,
            Sidebar
        },
        setup(){
          const loading = ref(true);
          const userStore = useUserStore();
          userStore.loadUser().catch(() => { //when page reloaded, need load user data again
            router.push('/login');
          }).finally(() => {
            loading.value = false;
          });
          return {
            loading,
            userStore,
          }
        },
        mounted() {
        },
        methods: {
        }
    }
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" src="./assets/styles/base.scss"></style>
