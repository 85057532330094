<template>
  <div id="billing-payment" class="billing-form">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>
    <div class="d-wrapper">
      <div class="d-form d-form-payment card-choice">
        <div class="d-form__title">
          <div class="d-form__title-wrap">
            Payment Method
          </div>
        </div>
        <div class="d-form__body">
          <div class="billing-title"><span>&#43;</span> Add payment method</div>
          <div class="checkbox-description">Select primary and backup payment methods</div>
          <div class="list-group">
            <div v-for="element in list" :key="element.id">
              <div   :class="{'list-group-item': true, 'active': element.active}"  @click="setActive(element.id)">
                <div class="list-group-item__info">
                  <div :class="{'list-group-item__button': true, 'active': element.active}"></div>
                  <div class="list-group-item__text">{{ element.title }}</div>
                </div>
                <div class="list-group-item__img">
                  <img v-if="element.id === 1" src="../assets/icons/cards.png" alt="">
                  <img v-if="element.id === 2" src="../assets/icons/paypal.png" alt="">
                </div>
              </div>
              <div v-if="element.id===1 && element.active" :class="{'item-collapse': true, 'active': element.active}">
                <div class="item-collapse__row">
                  <div class="item-collapse__col">
                    <div class="d-form-group">
                      <label for="card_number" class="d-form-label">Card number</label>
                      <div class="d-form-content">
                        <input type="text" id="card_number" class="control" placeholder="4580 XXXX">
                      </div>
                    </div>
                    <div class="d-form-group">
                      <label for="name" class="d-form-label">Name on card</label>
                      <div class="d-form-content">
                        <input type="text" id="name" class="control" placeholder="Name">
                      </div>
                    </div>
                  </div>
                  <div class="item-collapse__col">
                    <div class="d-form-group">
                      <label for="expiration" class="d-form-label">Expiration</label>
                      <div class="d-form-content">
                        <input type="text" id="expiration" class="control" placeholder="MM/YY">
                      </div>
                    </div>
                    <div class="d-form-group">
                      <label for="cvv" class="d-form-label">CVV</label>
                      <div class="d-form-content">
                        <input type="text" id="cvv" class="control" placeholder="XXX">
                      </div>
                    </div>
                  </div>
                </div>                
                <div class="btn activate-button">Save payment method</div>
              </div>
              <div v-if="element.id===2 && element.active" :class="{'item-collapse': true, 'active': element.active}">
                <div class="btn activate-button">Connect PayPal account</div>
              </div>
            </div>           
          </div>
        </div>
      </div>
      <div class="d-form d-form-payment user-payments">
        <div class="d-form__title">
          <div class="d-form__title-wrap">Payment Method</div>
        </div>
        <div class="d-form__body">
          <div class="billing-title">My Payment methods</div>
          <div class="list-group">
            <div class="list-group-empty" v-if="!userMethods.length">
              No payment methods on record
            </div>
            <draggable
                v-else
                :list="userMethods"
                :disabled="!enabled"
                item-key="name"
                class="list-group"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging = true"
                @end="dragging = false">
              <template #item="{element}">
                <div>
                  <div :class="{'list-group-item': true, 'payment-item': true, 'active': element.active}">
                    <div class="payment-img">
                      <img v-if="element.type==='visa'" src="../assets/icons/visa.svg" alt="">
                      <img v-if="element.type==='maestro'" src="../assets/icons/maestro.png" alt="">
                      <img v-if="element.type==='paypal'" src="../assets/icons/paypal-sm.png" alt="">
                    </div>
                    <div class="list-group-item__text col-sm">{{element.title}}</div>
                    <div class="payment-bold-text col-lg">{{element.requisites}}</div>
                    <div class="payment-expire col-sm">
                      <div class="list-group-item__text" v-if="element.title==='Credit Card'">Expires</div>
                      <div class="payment-bold-text no-margin">
                        {{element.expire}}
                        <img v-if="element.type === 'paypal'" src="../assets/icons/verified.png" alt="">
                      </div>
                    </div>
                    <div class="list-group-item__text col-m">{{element.usage}}</div>
                    <div class="payment-bold-text delete" v-if="element.active" @click="openDeleteModal(element.id)">Delete</div>
                    <div :class="{'payment-action':true, 'active': element.active}" @click="editPayment(element.id)">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div :class="{'payment-collapse': true, 'active': element.active}" v-if="element.title==='Credit Card' && element.active">
                    <div class="d-form-group">
                      <label for="expire" class="d-form-label">Expiration</label>
                      <div class="d-form-content">
                        <input type="text" id="expire" class="control" placeholder="MM/YY">
                      </div>
                    </div>
                    <div class="d-form-group">
                      <label for="card_cvv" class="d-form-label">CVV</label>
                      <div class="d-form-content">
                        <input type="text" id="card_cvv" class="control" placeholder="XXX">
                      </div>
                    </div>
                    <div class="btn activate-button">Update and close</div>
                  </div>
                </div>                
              </template>
            </draggable>
          </div>
        </div>
      </div>      
    </div>
    <modal :visible="showModalRemove" :close="() => {this.showModalRemove = false}" className="dialog remove-payment-modal" >
      <div @click="() => {this.showModalRemove = false}" class="btn-cross"><img class="svg" src="../assets/icons/close.svg"/></div>
      <div class="header">
        <img class="svg" src="../assets/icons/message.svg"/>
      </div>
      <div class="body">
        <div class="title warning"> Are you sure you want to remove this payment method? </div>
      </div>
      <div class="footer">
        <div @click="() => {this.showModalRemove = false}" class="btn btn-ok">
          Abort
        </div>
        <div @click="acceptRemovingItem" :class="{'btn': true, 'btn-ok': true}">
          Apply
        </div>
      </div>
    </modal>
  </div>
</template>

<style src="@vueform/toggle/themes/default.css"></style>
<script>

import Breadcrumbs from "./controls/breadcrumbs";
import draggable from "vuedraggable";

export default {
  components: {Breadcrumbs, draggable},
  name: 'BillingPayment',
  props: {},
  data() {
    return {
      showModal: false,
      breadcrumbs: [
        {
          "name": "Dashboard",
          "link": "/dashboard",
          "active": false
        },
        {
          "name": "Payment Method",
          "link": "#",
          "active": true
        }
      ],
      paymentType: {
        "card": true,
        "paypal": false
      },
      loadBalanceNotification: false,
      autoBalance: false,
      list: [
        { title: 'Credit Card / Debit Card', id: 1, active: false },
        { title: 'PayPal account', id: 2, active: false },
        { title: 'Bank Transfer', id: 3, active: false }
      ],
      enabled: true,
      dragging: false,
      userMethods: [
        { id: 0, type: 'visa', title: 'Credit Card', requisites: 'XXXX-XXXX-XXXX-9688', expire: '22/12/22', usage: 'Default', active: false },
        { id: 1, type: 'paypal', title: 'PayPal account', requisites: 'Orgname', expire: 'Verified', usage: 'Backup 1', active: false },
        { id: 2, type: 'maestro', title: 'Credit Card', requisites: 'XXXX-XXXX-XXXX-9688', expire: '22/12/22', usage: 'Backup 2', active: false },
      ],
      showModalRemove: false,
      removeItem: {},
    };    
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    selectPayment: function(type) {
      if(type === 1) {
        this.paymentType.card = true;
        this.paymentType.paypal = false;
      } else {
        this.paymentType.card = false;
        this.paymentType.paypal = true;
      }
    },
    show: function () {
      this.showModal = true;
    },
    hide: function () {
      this.showModal = false;
    },
    save: function () {
    },
    cancel: function () {
    },
    checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    setActive: function (id) {
      this.list.forEach((el) => {
        el.active = el.id === id ? !el.active : false;
      })
    },
    editPayment: function (id) {
      this.userMethods.forEach((el) => {
        el.active = el.id === id? !el.active : false;
      })
    },
    openDeleteModal: function(id) {
      this.showModalRemove = true;
      this.removeItem = this.userMethods.find(i => i.id === id);
    },
    acceptRemovingItem: function () {
      
    }
  }
}
</script>
<style lang="scss" scoped src="../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../assets/styles/billing.scss"></style>