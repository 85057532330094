<template>
  <div id="billing-overview" class="default_container light-blue-background">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>
    <div class="d-wrapper">
      <div class="d-form">
        <div class="d-form__title">
          <div class="d-form__title-wrap">
            Users
          </div>
        </div>
        <Users />
      </div>
    </div>
  </div>
</template>
<script>

import Breadcrumbs from "./controls/breadcrumbs";
import Users from "./user_profile_details_tabs/users";

export default {
  components: {Breadcrumbs, Users},
  name: 'UserProfileContainer',
  props: {},
  data() {
    return {
      breadcrumbs: [
        {
          "name": "Settings",
          "link": "/user-profile-details",
          "active": false
        },
        {
          "name": "Users",
          "link": "#",
          "active": true
        }
      ],
    };
  },
  mounted() {

  },
  methods: {
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../assets/styles/settings.scss"></style>

