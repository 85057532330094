<template>
    <div id="register" v-if="!loading">
        <form class="form-login">
            <div class="form-login__left-side">
            </div>
            <div class="form-login__right-side">
                <div class="form-login-data-wrap">
                    <div class="form-login-data-title">Welcome aboard!!</div>
                    <div class="login-sub-text">
                        Thanks for choosing Nemesysco’s voice analysis cloud service. You are a few short steps away
                        from launching into the world of genuine emotion detection. To create your account, we would
                        love to learn a bit about you and your company, and how we may be of service to you. Please fill
                        in all the details below, then click “create your account”.
                    </div>
                    <span v-if="genError" class="error_message">{{genError}}</span>
                    <div class="login-group required">
                        <label for="FirstName"></label>
                        <input id="FirstName" type="text" :class="errors?.FirstName ? 'login-control has_error' : 'login-control'" placeholder="First name" v-model="registerForm.FirstName">
                    </div>
                    <span v-if="errors?.FirstName" class="error_message">This field is required</span>
                    <div class="login-group required">
                      <label for="LastName"></label>
                      <input id="LastName" type="text" :class="errors?.LastName ? 'login-control has_error' : 'login-control'" placeholder="Last name" v-model="registerForm.LastName">
                    </div>
                    <span v-if="errors?.LastName" class="error_message">This field is required</span>
                    <div class="login-group required">
                      <Multiselect searchable placeholder="Country" id="CountryId" v-model="registerForm.CountryId" :options="userStore.countries" :class="errors?.CountryId ? 'login-control has_error' : 'login-control'"/>
                    </div>
                  <span v-if="errors?.CountryId" class="error_message">This field is required</span>
                    <div class="login-group required">
                        <label for="Email"></label>
                        <input id="Email" type="text" :class="errors?.Email ? 'login-control has_error' : 'login-control'" placeholder="Email" v-model="registerForm.Email">
                    </div>
                    <span v-if="errors?.Email" class="error_message">This field is required</span>
                    <div class="login-group required">
                        <label for="Phone"></label>
                        <input id="Phone" type="text" :class="errors?.Phone ? 'login-control has_error' : 'login-control'" placeholder="Phone number" v-model="registerForm.Phone">
                    </div>
                    <span v-if="errors?.Phone" class="error_message">This field is required</span>
                    <div class="login-group required">
                        <label for="OrganizationName"></label>
                        <input id="OrganizationName" type="text" :class="errors?.OrganizationName ? 'login-control has_error' : 'login-control'" placeholder="Organization name" v-model="registerForm.OrganizationName">
                    </div>
                    <span v-if="errors?.OrganizationName" class="error_message">This field is required</span>
                    <div class="login-group non-required">
                      <label for="input-affiliate"></label>
                      <input id="input-affiliate" type="text" class="login-control" placeholder="Affiliate code" v-model="registerForm.AffiliateCode">
                    </div>
                    <div class="login-group non-required">
                        <Multiselect v-model="registerForm.PurposeType" id="PurposeType" :options="describes" class="multiselect-blue"/>
                    </div>
                    <div class="login-terms">
                        <div class="d-form-group__check-text">
                            <label class="container-checkbox" for="AcceptTermsAndConditions">
                                <input type="checkbox" id="AcceptTermsAndConditions" v-model="registerForm.AcceptTermsAndConditions">
                                <span :class="errors?.AcceptTermsAndConditions ? 'checkmark has_error' : 'checkmark'"></span>
                            </label>
                            <div>
                                By clicking here, I state that I have read, understood and accept the <a target="_blank"
                                                                                                         href="http://www.nemesysco.com/privacy-policy/">terms
                                and conditions</a> and <a target="_blank"
                                                          href="https://www.nemesysco.com/data-processing-and-protection/">Nemesysco
                                Data Processing agreement</a>.
                            </div>
                        </div>
                    </div>
                    <span v-if="errors?.AcceptTermsAndConditions" class="error_message" style="margin-bottom: 0">This field is required</span>
                    <div class="login-terms">
                        <div class="d-form-group__check-text">
                            <label class="container-checkbox">
                                <input type="checkbox" v-model="registerForm.SendNotification">
                                <span class="checkmark"></span>
                            </label>
                            <div>
                              Yes, I agree to receive emails from you.
                            </div>
                        </div>
                    </div>
                    <button :disabled="disabledButton" class="login-submit" @click="register">Create Your Account</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

    import router from "@/routes";
    import {useUserStore} from "@/stores/userStore";

    export default {
        components: {},
        name: 'Register',
        props: {},
        data() {
            return {
                loading: false,
                disabledButton: false,
                registerForm: {
                    'FirstName': '',
                    'LastName': '',
                    'CountryId': 0,
                    'Email': '',
                    'Phone': '',
                    'OrganizationName': '',
                    'AffiliateCode': '',
                    'PurposeType': '',
                    'AcceptTermsAndConditions': false,
                    'SendNotification': true,
                },
                showPassword: false,
                password: null,
                describes: [
                    {value: '', label: 'Please select one'},
                    {value: 'Entertainment/personal use', label: 'Entertainment/personal use'},
                    {value: 'Just emotional analysis for corporate needs', label: 'Just emotional analysis for corporate needs'},
                    {value: 'Full emotional and risk analysis for professional uses', label: 'Full emotional and risk analysis for professional uses'},
                ],
                errors: {},
                genError: '',
            }
        },
        setup(){
          const userStore = useUserStore();
          userStore.loadCountries();
          return {
            userStore,
          }
        },
        mounted() {
            this.svgHelper();
            this.loading = true;
            this.userStore.loadUser().then(() => {
              if (localStorage.getItem('token') !== null) {
                router.push('/dashboard');
              }
            }).finally(() => {
              this.loading = false;
            });
        },
        methods: {
            toggleShow() {
                this.showPassword = !this.showPassword;
            },
            insertAfter(referenceNode, newNode) {
                referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
            },
            register(e) {
              //console.log(this.describe);
              e.preventDefault();
              e.stopPropagation();
              this.errors = {};
              this.genError = '';
              this.axios.post('Organizations/register', this.registerForm)
              .then((response) => {
                if(response) this.disabledButton = true;
                router.push('/login');
              }).catch((err) => {
                if(err.response.status === 400) {
                  if(err.response.data.message) {
                    this.genError = err.response.data.message;
                  }                  
                  this.errors = err.response.data.errors;
                  if(this.registerForm.CountryId === 0) {
                    this.errors['CountryId'] = 'error'; 
                  }
                }
              });
            },
        }
    }
</script>

<style scoped lang="scss" src="../assets/styles/authorization.scss"></style>
