<template>
  <div class="settings">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>
    <div class="d-wrapper">
      <div class="d-form">
        <div class="d-form__title">
          <div class="d-form__title-wrap">
            Settings
          </div>
        </div>
        <Tabs :tabs="tabs"></Tabs>
      </div>
    </div>
  </div>
</template>
<script>

import Breadcrumbs from "../controls/breadcrumbs";
import Tabs from "../shared/tabs";
import Roles from "./settings_tabs/roles";
import Licenses from "./settings_tabs/licenses";
import ApplicationType from "./settings_tabs/application_type";
import TypeOfUse from "./settings_tabs/type_of_use";
import PermissionLevel from "./settings_tabs/permission_level";
import General from "./settings_tabs/general";

import { shallowRef } from 'vue';
const RolesRef = shallowRef(Roles);
const LicensesRef = shallowRef(Licenses);
const ApplicationTypeRef = shallowRef(ApplicationType);
const TypeOfUseRef = shallowRef(TypeOfUse);
const PermissionLevelRef = shallowRef(PermissionLevel);
const GeneralRef = shallowRef(General);

export default {
  components: {Breadcrumbs, Tabs},
  name: 'Settings',
  props: {},
  data() {
    return {
      breadcrumbs: [
        {
          "name": "Dashboard",
          "link": "/dashboard",
          "active": false
        },{
          "name": "Settings",
          "link": "/admin/settings",
          "active": true
        },
      ],
      tabs: [
        {
          title: "Roles",
          component: RolesRef,
          data: {},
        },{
          title: "Licenses",
          component: LicensesRef,
          data: {},
        },{
          title: "Application type",
          component: ApplicationTypeRef,
          data: {},
        },{
          title: "Type of use",
          component: TypeOfUseRef,
          data: {},
        },{
          title: "Permission level",
          component: PermissionLevelRef,
          data: {},
        },{
          title: "Countries",
          component: 'div',
          data: {},
        },{
          title: "General",
          component: GeneralRef,
          data: {},
        }
      ]
    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../assets/styles/admin/settings.scss"></style>

