<template>
  <div class="grid-container sites">
    <div class="row-navigation">
      <div class="row-navigation-actions row-navigation-actions-simple">
        <div class="grid-search-wrapper">
          <div class="global-search-icon">
            <img class="svg" src="../../../assets/icons/grid-search.svg"/>
          </div>
          <input class="grid-search" type="text" value="" placeholder="Search Company">
        </div>
      </div>
      <div class="row-navigation-exports">
        <div class="row-navigation-exports">
          <div class="export-button">
            <img class="svg" src="../../../assets/icons/pdf.svg"/>
          </div>
          <div class="export-button">
            <img class="svg" src="../../../assets/icons/csv.svg"/>
          </div>
          <div class="export-button">
            <img class="svg" src="../../../assets/icons/json-file.svg"/>
          </div>
        </div>
      </div>
    </div>
    <table class="grid-table">
      <thead>
      <th class="checkbox-th">
        <label class="container-checkbox">
          <input type="checkbox" checked="checked">
          <span class="checkmark"></span>
        </label>
      </th>
      <th :key="header" v-for="header in grid_heads">
        <div class="grid-header-data">
          {{ header.label }}
          <div v-if="header.sortable" class="grid-sort-order">
            <div class="triangle-up"></div>
            <div class="triangle-down"></div>
          </div>
        </div>
      </th>
      </thead>
      <tbody>
      <tr :key="key" v-for="(row, key) in grid_rows">
        <td class="checkbox-tr">
          <label class="container-checkbox">
            <input type="checkbox">
            <span class="checkmark"></span>
          </label>
        </td>
        <td>{{ row.entity_name }}</td>
        <td>{{ row.ref_code }}</td>
        <td>{{ row.perm_level }}</td>
        <td>{{ row.partner_level }}</td>
        <td v-bind:class="getColumnStatusClasses(row.status)">{{ row.status }}</td>
        <td>{{ row.active_sites }}</td>
        <td>{{ row.daily_errors }}</td>
        <td>{{ row.total_cocker_min }}</td>
        <td>{{ row.total_cloud_usage }}</td>
        <td>{{ row.current_balance }}</td>
        <td><span v-bind:class="getColumnPaymentStatusClasses(row.payment_status)">{{ row.payment_status }}</span></td>
        <td class="table-actions">
          <div v-on:click="companyDetails">
            <div class="view-grid-button">View Company</div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>



<script>

export default {
  components: {},
  name: 'Users',
  props: {},
  data() {
    return {
      grid_heads: [
        {sortable: true, label: 'Entity name'},
        {sortable: true, label: 'Ref. code'},
        {sortable: true, label: 'Perm. level'},
        {sortable: true, label: 'Partner level'},
        {sortable: true, label: 'Status'},
        {sortable: true, label: 'Active sites'},
        {sortable: true, label: 'Daily Errors'},
        {sortable: true, label: 'Total cocker Min'},
        {sortable: true, label: 'Total cloud usage'},
        {sortable: true, label: 'Current balance'},
        {sortable: true, label: 'Payment status'},
        {sortable: false, label: 'Quick Actions'},
      ],
      grid_rows: [
        {
          'entity_name': 'Sony',
          'ref_code': '24664',
          'perm_level': '2',
          'partner_level': 'Pre-paid',
          'status': 'Active',
          'active_sites': '11',
          'daily_errors': '0',
          'total_cocker_min': '20,541 M',
          'total_cloud_usage': '20,541 M',
          'current_balance': '$59,460',
          'payment_status': 'Paid',
        }, {
          'entity_name': 'Google',
          'ref_code': '24566',
          'perm_level': '2',
          'partner_level': 'Silver',
          'status': 'Active',
          'active_sites': '23',
          'daily_errors': '5',
          'total_cocker_min': '50,541 M',
          'total_cloud_usage': '50,541 M',
          'current_balance': '$5,590',
          'payment_status': 'Paid',
        }, {
          'entity_name': 'Meta',
          'ref_code': '66544',
          'perm_level': '3',
          'partner_level': 'Silver',
          'status': 'Active',
          'active_sites': '12',
          'daily_errors': '0',
          'total_cocker_min': '90,541 M',
          'total_cloud_usage': '90,541 M',
          'current_balance': '$17,584',
          'payment_status': 'Paid',
        }, {
          'entity_name': 'Amazon',
          'ref_code': '77544',
          'perm_level': '4',
          'partner_level': 'Gold',
          'status': 'Active',
          'active_sites': '41',
          'daily_errors': '15',
          'total_cocker_min': '45,541 M',
          'total_cloud_usage': '45,541 M',
          'current_balance': '$20,550',
          'payment_status': 'Paid',
        }, {
          'entity_name': 'Twilio',
          'ref_code': '27744',
          'perm_level': '2',
          'partner_level': 'Silver',
          'status': 'Active',
          'active_sites': '40',
          'daily_errors': '0',
          'total_cocker_min': '1231 M',
          'total_cloud_usage': '1231 M',
          'current_balance': '$1,211',
          'payment_status': 'Overdue',
        }, {
          'entity_name': 'Samsung',
          'ref_code': '24584',
          'perm_level': '3',
          'partner_level': 'Pre-paid',
          'status': 'Active',
          'active_sites': '19',
          'daily_errors': '9',
          'total_cocker_min': '5009 M',
          'total_cloud_usage': '5009 M',
          'current_balance': '$20,550',
          'payment_status': 'Due',
        }, {
          'entity_name': 'Call Journey',
          'ref_code': '88454',
          'perm_level': '3',
          'partner_level': 'Pre-paid',
          'status': 'Active',
          'active_sites': '3',
          'daily_errors': '0',
          'total_cocker_min': '1231 M',
          'total_cloud_usage': '1231 M',
          'current_balance': '$1,211',
          'payment_status': 'Paid',
        },
      ],
    };
  },
  mounted() {
    this.svgHelper();
  },
  methods: {
    getColumnStatusClasses(status) {
      return {
        'grid-simple-status-label__success': status === 'Active',
      };
    },
    getColumnPaymentStatusClasses(status) {
      return {
        'grid-status-label': true,
        'grid-status-label__suspended': status === 'Overdue',
        'grid-status-label__success': status === 'Paid',
        'grid-status-label__active': status === 'Due',
      };
    },
    companyDetails: function () {
      this.$router.push('/admin/company/details');
    },
  }
}
</script>
<style lang="scss" scoped src="../../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../../assets/styles/admin/company_details.scss"></style>
