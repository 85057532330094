<template>
  <div id="users-list">
    <breadcrumbs :items="breadcrumbs"></breadcrumbs>
    <div class="grid-container">
      <div class="row-navigation">
        <div class="row-navigation-actions">
          <div class="grid-search-wrapper">
            <div class="global-search-icon">
              <img class="svg" src="../../assets/icons/grid-search.svg"/>
            </div>
            <input class="grid-search" type="text" value="" placeholder="Search User">
          </div>
        </div>
        <div class="row-navigation-exports">
          <div class="row-navigation-exports">
            <div class="export-button">
              <img class="svg" src="../../assets/icons/pdf.svg"/>
            </div>
            <div class="export-button">
              <img class="svg" src="../../assets/icons/csv.svg"/>
            </div>
            <div class="export-button">
              <img class="svg" src="../../assets/icons/json-file.svg"/>
            </div>
          </div>
        </div>
      </div>
      <table class="grid-table">
        <thead>
          <th class="checkbox-th">
            <label class="container-checkbox">
              <input type="checkbox" checked="checked">
              <span class="checkmark"></span>
            </label>
          </th>
          <th :key="header" v-for="header in grid_heads">
            <div class="grid-header-data">
              {{ header.label }}
              <div v-if="header.sortable" class="grid-sort-order">
                <div class="triangle-up"></div>
                <div class="triangle-down"></div>
              </div>
            </div>
          </th>
        </thead>
        <tbody>
        <tr :key="key" v-for="(row, key) in grid_rows">
          <td class="checkbox-tr">
            <label class="container-checkbox">
              <input type="checkbox">
              <span class="checkmark"></span>
            </label>
          </td>
          <td>{{ row.name }}</td>
          <td>{{ row.org_name }}</td>
          <td>{{ row.org_status }}</td>
          <td>{{ row.last_login }}</td>
          <td>{{ row.user_type }}</td>
          <td class="quick-actions">
            <div class="action">
              <img class="svg" src="../../assets/icons/folder.svg"/>
            </div>
            <div class="action">
              <img class="svg" src="../../assets/icons/paper-plane.svg"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
import Breadcrumbs from "../controls/breadcrumbs";

export default {
  components: {Breadcrumbs},
  name: 'Users',
  props: {},
  data() {
    return {
      breadcrumbs: [
        {
          "name": "Dashboard",
          "link": "/dashboard",
          "active": false
        },
        {
          "name": "Users",
          "link": "#",
          "active": true
        }
      ],
      grid_heads: [
        {sortable: true, label: 'User Name'},
        {sortable: true, label: 'Org Name'},
        {sortable: true, label: 'Org Status'},
        {sortable: true, label: 'Last Login'},
        {sortable: true, label: 'User Type'},
        {sortable: false, label: 'Quick Actions'},
      ],
      grid_rows: [
        {
          'name': 'Customer000',
          'org_name': 'Corp',
          'org_status': 'Active',
          'org_licence': 'Silver',
          'last_login': '12.12.21',
          'user_type': 'IT'
        },
        {
          'name': 'Customer000',
          'org_name': 'Corp',
          'org_status': 'Active',
          'org_licence': 'Silver',
          'last_login': '12.12.21',
          'user_type': 'IT'
        },
        {
          'name': 'Customer000',
          'org_name': 'Corp',
          'org_status': 'Active',
          'org_licence': 'Silver',
          'last_login': '12.12.21',
          'user_type': 'IT'
        },
      ],
    };
  },
  mounted() {
    this.svgHelper();
  },
  methods: {}
}
</script>
<style lang="scss" scoped src="../../assets/styles/admin/users.scss"></style>

