<template>
  <div class="company-profile">
    <div class="tab-container">
      <div class="with-separator">
        <div class="billing-title">Common Details</div>
        <div class="d-form-group">
          <label class="d-form-label">Company Name</label>
          <div class="d-form-content">
            <input class="control" type="text" value="Money LTD.">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Company Web Site</label>
          <div class="d-form-content">
            <input class="control" type="text" value="johndoe.com">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">VAT/TAX ID</label>
          <div class="d-form-content">
            <input class="control" type="text" value="5461618484">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Phone No.</label>
          <div class="d-form-content">
            <input class="control" type="text" value="+81521685998">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Notifications Email</label>
          <div class="d-form-content">
            <input class="control" type="text" value="Johndoe@mail.com">
          </div>
        </div>
      </div>
      <div class="with-separator">
        <div class="billing-title">Company Address</div>
        <div class="d-form-group">
          <label class="d-form-label">Country</label>
          <div class="d-form-content">
            <input class="control" type="text" value="Japan">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">State/Province</label>
          <div class="d-form-content">
            <input class="control" type="text" value="-">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Street Address</label>
          <div class="d-form-content">
            <input class="control" type="text" value="1-7-1 Konan Minata-ku, Tokio">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Zip Code</label>
          <div class="d-form-content">
            <input class="control" type="text" value="88464321923">
          </div>
        </div>
      </div>
      <div class="with-separator">
        <div class="billing-title">
          <span class="label">Billing Address</span>
          <div class="d-form-group__check-text">
            <label class="container-checkbox">
              <input type="checkbox" checked="checked">
              <span class="checkmark"></span>
            </label>
            <div>
              Same as company address
            </div>
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Country</label>
          <div class="d-form-content">
            <input class="control" type="text" value="Japan">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">State/Province</label>
          <div class="d-form-content">
            <input class="control" type="text" value="-">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Street Address</label>
          <div class="d-form-content">
            <input class="control" type="text" value="1-7-1 Konan Minata-ku, Tokio">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Zip Code</label>
          <div class="d-form-content">
            <input class="control" type="text" value="88464321923">
          </div>
        </div>
        <div class="d-form-group">
          <label class="d-form-label">Billing Email</label>
          <div class="d-form-content">
            <input class="control" type="text" value="Johndoe@mail.com; lili@mail.com; jack@mail.com">
          </div>
        </div>
      </div>
    </div>
    <div class="d-form__action">
      <div class="btn activate-button center">
        Update
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  name: 'CompanyProfile',
  props: {},
  data() {
    return {
      showModal: false,
      showPassword: false,
    };
  },
  mounted() {
    this.svgHelper()
  },
  methods: {
    save: function () {

    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../../../assets/styles/components.scss"></style>
<style lang="scss" scoped src="../../../assets/styles/admin/company_details.scss"></style>

