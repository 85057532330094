<template>
  <div id="dashboard">
    <div v-if="is_new_user" class="welcome-new-user">
      <div class="welcome-new-user__handshake">
        <img class="svg" src="../assets/icons/handshake.svg"/>
      </div>
      <div class="welcome-new-user__title">
        Hi! Welcome aboard!
      </div>
      <div class="welcome-new-user__desc">
        This area is where you will find your site usage stats and charts.
      </div>
      <div class="welcome-new-user__desc">
        Looks like there is no site usage data yet…But worry not!
      </div>
      <div class="welcome-new-user__desc">
        As soon as your sites begin analyzing voice the information will be right here waiting for you.
      </div>
    </div>
    <div v-if="!is_new_user">
      <div class="dashboard-header">
        <div class="dashboard-header__statistic">
          <div style="display: none;">
            <div class="global-search-icon">
              <img class="svg" src="../assets/icons/search.svg"/>
            </div>
            <input class="global-search" type="text" value="" placeholder="Search">
          </div>
        </div>
      </div>
      <div class="chart-row">
        <div class="chart-container total-chart">
          <div class="chart-header-container">
            <div class="chart-title">
              Total Processed files
            </div>
            <div class="chart-datepicker">
              <Datepicker v-model="date" :enableTimePicker="false" range twoCalendars></Datepicker>
            </div>
          </div>
          <div class="chart-content">
            <div class="circle">
              <v-chart class="chart-graph" :option="options_c"/>
              <div class="total">
                <div class="total__data">16,252</div>
                <div class="total__text">Total Files</div>
              </div>
            </div>
            <div class="chart-data">
              <div class="chart-data-desc">
                <div class="chart-item-row chart-item-row__first"></div>
                <div>
                  <div class="chart-item-data-text-title">Total files analyzed</div>
                  <div class="chart-item-data-text-total">4,053 Files</div>
                </div>
              </div>
              <div class="chart-data-desc">
                <div class="chart-item-row chart-item-row__second"></div>
                <div>
                  <div class="chart-item-data-text-title">Total files with errors</div>
                  <div class="chart-item-data-text-total">4,053 Files</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-container breakdown-chart">
          <div class="chart-header-container">
            <div class="chart-title">
              Data Breakdown
            </div>
            <div class="chart-btn-group">
              <div
                  v-for="(line, index) in chartLines"
                  :key="line.name"
                  :class="'btn '+line.name.toLowerCase()"
                  @click="activeChartLine = index"
              >
                {{line.name}}
              </div>
              <div class="chart-datepicker">
                <Datepicker v-model="date" :enableTimePicker="false" range twoCalendars></Datepicker>
              </div>
            </div>
          </div>
          <v-chart class="chart-graph" :option="options"/>
        </div>
      </div>
    </div>

    <div class="grid-container dashboard-grid">
      <div class="row-navigation">
        <div class="row-navigation-actions">
          <router-link to="/site/create" class="btn btn-add">
            <span>+</span> Create New Site
          </router-link>
          <div class="grid-search-wrapper">
            <div class="global-search-icon">
              <img class="svg" src="../assets/icons/grid-search.svg"/>
            </div>
            <input class="grid-search" type="text" value="" placeholder="Search Site">
          </div>
        </div>
        <div class="row-navigation-exports">
          <div class="export-button">
            <img class="svg" src="../assets/icons/pdf.svg"/>
          </div>
          <div class="export-button">
            <img class="svg" src="../assets/icons/csv.svg"/>
          </div>
          <div class="export-button">
            <img class="svg" src="../assets/icons/json-file.svg"/>
          </div>
        </div>
      </div>
      <table class="grid-table">
        <thead>
        <th class="checkbox-th">
          <label class="container-checkbox">
            <input type="checkbox" checked="checked">
            <span class="checkmark"></span>
          </label>
        </th>
        <th :key="header" v-for="header in grid_heads">
          <div class="grid-header-data">
            {{ header.label }}
            <div v-if="header.sortable" class="grid-sort-order">
              <div class="triangle-up"></div>
              <div class="triangle-down"></div>
            </div>
          </div>
        </th>
        </thead>
        <tbody>
        <tr :key="key" v-for="(row, key) in grid_rows">
          <td class="checkbox-tr">
            <label class="container-checkbox">
              <input type="checkbox">
              <span class="checkmark"></span>
            </label>
          </td>
          <td>{{ row.site_id }}</td>
          <td>{{ row.site_name }}</td>
          <td>{{ row.application }}</td>
          <td>{{ row.seats }}</td>
          <td>{{ row.last_activity }}</td>
          <td>{{ row.total_min }}</td>
          <td>{{ row.total_errors }}</td>
          <td>
            <span class="grid-status-label grid-status-label__active" v-if="row.status === 'Active'">
                {{ row.status }}
            </span>
            <span v-else class="grid-status-label grid-status-label__suspended">
                {{ row.status }}
            </span>
          </td>
          <td>{{ row.current_charge }}</td>
          <td class="table-actions">
            <div class="btn" v-on:click="edit">View Site</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from 'echarts/charts';
import {GridComponent} from 'echarts/components';
import {PieChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import {useUserStore} from "@/stores/userStore";

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

const dates = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(() => '10/21')

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
}

export default {
  components: {VChart, Datepicker},
  name: 'Dashboard',
  props: {},
  setup(){
    const userStore = useUserStore();
    return {
      userStore,
    }
  },
  data() {
    return {
      is_new_user: false,
      date: null,
      activeChartLine: 0,
      chartLines: [
        {
          name: 'Time',
          color: '#5394b2',
          data: dates.map(() => getRandomInt(5, 60)),
        },{
          name: 'Charge',
          color: '#77b5ff',
          data: dates.map(() => getRandomInt(5, 60)),
        },{
          name: 'Files',
          color: '#4a637a',
          data: dates.map(() => getRandomInt(5, 60)),
        },
      ],
      options_c: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: '50%',
              borderColor: '#fff',
              borderWidth: 15,
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 700, name: ''},
              {value: 600, name: ''},
            ]
          }
        ],
        color: ['#77b5ff', '#5394b2']
      },
      grid_heads: [
        {sortable: true, label: 'Site ID'},
        {sortable: true, label: 'Site Name'},
        {sortable: true, label: 'Application'},
        {sortable: true, label: '# Seats'},
        {sortable: true, label: 'Last Activity'},
        {sortable: true, label: 'Total Min'},
        {sortable: true, label: 'Total Failed'},
        {sortable: true, label: 'Status'},
        {sortable: true, label: 'Current charge'},
        {sortable: false, label: 'Quick Actions'},
      ],
      grid_rows: [
        {
          'site_id': '034534',
          'site_name': 'Dev.1',
          'application': 'Application',
          'seats': 'Seats',
          'last_activity': '12.12.21',
          'total_min': '59',
          'total_errors': '6',
          'status': 'Active',
          'current_charge': '$ 3.25',
        },
        {
          'site_id': '034534',
          'site_name': 'Dev.1',
          'application': 'Application',
          'seats': 'Seats',
          'last_activity': '12.12.21',
          'total_min': '59',
          'total_errors': '6',
          'status': 'Suspended',
          'current_charge': '$ 3.25',
        },
        {
          'site_id': '034534',
          'site_name': 'Dev.1',
          'application': 'Application',
          'seats': 'Seats',
          'last_activity': '12.12.21',
          'total_min': '59',
          'total_errors': '6',
          'status': 'Active',
          'current_charge': '$ 3.25',
        }
      ],
    };
  },
  computed: {
    options: function () {
      return {
        xAxis: {
          data: dates
        },
        yAxis: {
          type: 'value'
        },
        color: this.chartLines[this.activeChartLine].color,
        series: [{
          type: 'bar',
          data: this.chartLines[this.activeChartLine].data,
          barWidth: 35,
          itemStyle: {
            backgroundColor: '#fff'
          },
        }],
      }
    },
  },
  mounted() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let param = url.searchParams.get("is_new");
    if (param === '1') {
      this.is_new_user = true;
    }
    this.svgHelper();
  },
  methods: {
    edit: function () {
      this.userStore.loadUser();
      this.$router.push('/site/form');
    },
    save: function () {
    },
    cancel: function () {

    },
  }
}
</script>
<style lang="scss" scoped src="../assets/styles/dashboard.scss"></style>

